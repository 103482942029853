import Dhaka from "assets/img/cities/dhaka.png";
import Chattogram from "assets/img/cities/chattogram.png";
import Gazipur from "assets/img/cities/gazipur.jpg";
import Jashore from "assets/img/cities/jashore.png";
import Khulna from "assets/img/cities/khulna.png";
import Rajshahi from "assets/img/cities/rajshahi.jpg";
import Sylhet from "assets/img/cities/sylhet.png";


const SelectLocation = () => {
  return <div>
    <div className="text-3xl px-4 py-5">
      Select City
    </div>

    <div className="p-4 locations">
      <div className="loc_container">
        <div><img alt="" src={Dhaka} /></div>
        <div className="flex justify-end p-4 font-bold text-3xl text-[#615e58]">Dhaka</div>
      </div>
      <div className="loc_container">
        <div><img alt="" src={Chattogram} /></div>
        <div className="flex justify-end p-4 font-bold text-3xl text-[#615e58]">Dhaka</div>
      </div>
      <div className="loc_container">
        <div><img alt="" src={Gazipur} /></div>
        <div className="flex justify-end p-4 font-bold text-3xl text-[#615e58]">Dhaka</div>
      </div>
      <div className="loc_container">
        <div><img alt="" src={Jashore} /></div>
        <div className="flex justify-end p-4 font-bold text-3xl text-[#615e58]">Dhaka</div>
      </div>
      <div className="loc_container">
        <div><img alt="" src={Rajshahi} /></div>
        <div className="flex justify-end p-4 font-bold text-3xl text-[#615e58]">Dhaka</div>
      </div>
      <div className="loc_container">
        <div><img alt="" src={Sylhet} /></div>
        <div className="flex justify-end p-4 font-bold text-3xl text-[#615e58]">Dhaka</div>
      </div>
      <div className="loc_container">
        <div><img alt="" src={Khulna} /></div>
        <div className="flex justify-end p-4 font-bold text-3xl text-[#615e58]">Dhaka</div>
      </div>
    </div>
  </div>;
};

export default SelectLocation;
