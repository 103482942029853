const PaymentHistory = () => {
    return (<div>

        <div className="text-[#615e58] text-2xl font-bold">Payment History</div>
        <select>
            <option value="2022" selected>2022</option>
            <option value="2021">2021</option>
            <option value="2020">2020</option>
            <option value="2019">2019</option>
            <option value="2018">2018</option>
        </select>

        <div className="border">
            <div>
                <div className="p-3">
                    <div className="text-3xl text-[#615e58]">Available Balance</div>
                    <div className="text-5xl text-[#87a790]">&#2547;0</div>
                    <div className="text-xl text-[#8c8c8c] cursor-pointer">Details &gt;</div>
                </div>

            </div>
            <div className="border-t flex text-[#615e58]">
                <div className="w-[50%] text-center border-r p-5">Account Balance
                    &#2547;0</div>
                <div className="w-[50%] text-center p-5">Balance On Hold</div>
            </div>
        </div>

        <div className="border">
            <div className="p-4 flex items-center text-3xl text-[#615e58] overflow-auto">
                <span className="mx-6 cursor-pointer">Jan</span>
                <span className="mx-6 cursor-pointer">Feb</span>
                <span className="mx-6 cursor-pointer">Mar</span>
                <span className="mx-6 cursor-pointer">Apr</span>
                <span className="mx-6 cursor-pointer">May</span>
                <span className="mx-6 cursor-pointer">Jun</span>
                <span className="mx-6 cursor-pointer">Jul</span>
                <span className="mx-6 cursor-pointer">Aug</span>
                <span className="mx-6 cursor-pointer">Sep</span>
                <span className="mx-6 cursor-pointer">Oct</span>
                <span className="mx-6 cursor-pointer">Nov</span>
                <span className="mx-6 cursor-pointer">Dec</span>
            </div>
        </div>

        <div>
            <div className="text-center text-[#ccc]">No Transactions</div>
        </div>
    </div>
    );
}

export default PaymentHistory;