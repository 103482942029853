import AppConfig from "config/app";

const More = (props) => {
    return (
        <div className="w-[280px]">
            <ul className="bg-white text-left text-[19px]">
                <li className="bg-[#4f4f4f] text-white p-4 cursor-pointer">
                    <span className="ml-1" >
                        {AppConfig.app_name}
                    </span>
                </li>
                <li className="px-4 pt-3 pb-7 cursor-pointer">
                    <svg width="24px" height="19px"
                        style={{ fill: "#4f4f4f", stroke: "#4f4f4f", display: "inline-block", verticalAlign: "middle" }}
                        viewBox="0 0 100 100"><g ><path d="m66.668 50l-33.336-34.512v17.844h-33.332v33.336h33.332v16.664z"></path></g></svg>
                    <span>&nbsp;Login</span>
                </li>
                <li className="px-4 pb-7 cursor-pointer">
                    <svg width="24px" height="19px" style={{ fill: "#4f4f4f", stroke: "#4f4f4f", display: "inline-block", verticalAlign: "middle" }} viewBox="0 0 100 100" data-reactid=".vtyjmovrmu.4.0.0.0.8.0.0.2.0.2.0"><g data-reactid=".vtyjmovrmu.4.0.0.0.8.0.0.2.0.2.0.0"><path d="m95.266 63h-15.266v-15.734c0-0.13672-0.51562-0.26562-0.64844-0.26562h-11.246c-0.13281 0-0.10547 0.12891-0.10547 0.26562v15.734h-15.812c-0.13281 0-0.1875 0.050781-0.1875 0.18359v11.242c0 0.13281 0.054688 0.57422 0.1875 0.57422h15.812v15.344c0 0.13281-0.027344 0.65625 0.10547 0.65625h11.246c0.13281 0 0.64844-0.52344 0.64844-0.65625v-15.344h15.266c0.13672 0 0.73438-0.44141 0.73438-0.57422v-11.242c0-0.13281-0.59766-0.18359-0.73438-0.18359z" data-reactid=".vtyjmovrmu.4.0.0.0.8.0.0.2.0.2.0.0.0"></path><path d="m52.188 77c-1.2344 0-2.1875-1.3398-2.1875-2.5742v-11.242c0-1.2383 0.95312-2.1836 2.1875-2.1836h13.812v-9.7461c-3.918-4.6367-9.1484-8.1641-15.156-9.9883 6.0234-3.3125 10.109-9.6836 10.109-17.004 0-10.727-8.7617-19.422-19.57-19.422-10.809 0-19.566 8.6953-19.566 19.422 0 7.3203 4.082 13.691 10.109 17.004-13.051 3.9609-22.527 15.887-22.527 29.996 0 17.34 14.316 20.324 31.98 20.324 9.9023 0 18.754-0.9375 24.617-4.8203l0.003906-9.7656z" data-reactid=".vtyjmovrmu.4.0.0.0.8.0.0.2.0.2.0.0.1"></path></g></svg>
                    <span>&nbsp;Sign Up</span>
                </li>
                <li className="px-4 pb-7 flex justify-between cursor-pointer">
                    <span>
                        <svg width="24px" height="19px" style={{ fill: "#4f4f4f", stroke: "#4f4f4f", display: "inline-block", verticalAlign: "middle" }} version="1.1" viewBox="0 0 100 100"><g ><path d="m34.324 42.16c-3.6836 3.043-8.5195 6.0039-14.902 8.5312l-1.4727-3.7148c5.7148-2.2656 10.059-4.8828 13.379-7.5625-4.0391-4.1055-6.2031-8.1836-7.3477-11.438h4.2695c1.0898 2.5703 2.9492 5.6406 6.0625 8.7656 5.5352-5.5703 7.0781-10.98 7.5078-13.648l-25.129 0.003906v-4.2148h15.199v-4.7695h4.2148v4.7695h15.199v4.2148h-5.4453c-0.38281 3.1055-1.9805 9.6875-8.5391 16.336 0.71094 0.57031 1.4766 1.1367 2.2852 1.6953 0.80469-1.0742 2.0781-1.7812 3.5234-1.7812h19.156c0.35938-1.8125 0.55859-3.6836 0.55859-5.6016 0-15.715-12.738-28.457-28.457-28.457-15.715 0-28.457 12.742-28.457 28.457s12.742 28.453 28.457 28.453c1.4766 0 2.9102-0.14453 4.3281-0.35938v-16.488c-1.6211-1.043-3.082-2.1133-4.3906-3.1914z"></path><path d="m67.902 55.926l-5.3789 14.801h10.754l-5.2852-14.801z"></path><path d="m90.078 42.125h-44.598c-2.207 0-3.9922 1.7891-3.9922 3.9922v44.598c0 2.2031 1.7891 3.9922 3.9922 3.9922h44.598c2.2031 0 3.9922-1.7891 3.9922-3.9922v-44.598c0-2.207-1.7891-3.9922-3.9922-3.9922zm-12 41.711l-3.4648-9.5547h-13.422l-3.5586 9.5547h-4.3086l12.355-31.73h4.6641l12.398 31.73z"></path></g></svg>
                        <span>&nbsp;Language</span>
                    </span>
                    <span className="mr-5 border-2 h-[20px] flex items-center border-gray-700 text-xs">
                        <span className="px-1 cursor-pointer border-r-2 h-[20px] flex items-center border-gray-700">বাং</span>
                        <span className="px-1 cursor-pointer h-[20px] flex items-center bg-gray-700 text-white">EN</span>
                    </span>
                </li>
                <li className="px-4 pb-3 cursor-pointer">
                    <svg width="24px" height="17px" style={{ fill: "#4f4f4f", stroke: "#4f4f4f", display: "inline-block", verticalAlign: "middle" }} version="1.1" viewBox="0 0 100 100"><path d="m50 5c-24.898 0-45 20.102-45 45s20.102 45 45 45 45-20.102 45-45-20.102-45-45-45zm7.1016 70c0 2.1992-1.8984 4.1016-4.1016 4.1016h-6.1992c-2.1992 0-4.1016-1.8984-4.1016-4.1016v-26.199c0-2.3008 1.8984-4.1016 4.1016-4.1016h6.1992c2.1992 0 4.1016 1.8984 4.1016 4.1016zm-7.2031-37.102c-4.6016 0-8.3984-3.8008-8.3984-8.5 0-4.6992 3.8008-8.5 8.3984-8.5 4.6992 0 8.5 3.8008 8.5 8.5 0 4.7031-3.7969 8.5-8.5 8.5z"></path>
                    </svg>
                    <span>&nbsp;Help</span>
                </li>
            </ul>
        </div>);
}

export default More;