const StringUtils = {

    limit(string,count,limitter){
        if(string.length<=count){
            return string;
        }
        else {
            return string.substr(0,count)+limitter;
        }
    }

};

export default StringUtils;