import NoOrderImg from "assets/img/no-orders.png";

const UserOrders = () => {
    return (


        <div className="p-2">
            <div className="text-2xl text-[#615858]">My Orders</div>
            <div className="no-orders py-4 flex flex-col items-center justify-around bg-[#fbfadd]">
                <span>
                    <img src={NoOrderImg} alt="" />
                </span>
                <span className="font-semibold text-2xl text-[#615e58]">No Order History Found</span>
            </div>

            <div className="flex p-2">
                <div className="border-r flex-grow">
                    <div className="max-w-[500px]">
                        <div className="border-2 flex flex-col justify-between">
                            <div className="flex justify-between p-3">
                                <div>
                                    <div className="bg-blue-500 rounded px-2 py-1 text-white font-bold text-sm">Order Status</div>
                                    <div>Order <span className="font-semibold">ID</span></div>
                                    <div>&#2547;&nbsp;Order Amount</div>
                                </div>
                                <div>
                                    <div className="flex">
                                        {[1, 2, 3, 4, 5].map((elm, index) => {
                                            return <span key={index} className="mx-1"><svg fill="#ebebeb" width="22" height="22" version="1.1" viewBox="0 0 1080 1080" x="0px" y="0px"><polygon points="540,90 679.057,386.262 990,433.771 765,664.376 818.115,990 540,836.261 261.884,990 315,664.376 90,433.769 400.943,386.262 "></polygon></svg></span>
                                        })}
                                    </div>
                                    <div>Shipment Name</div>
                                    <div>Date</div>
                                    <div>Probable Delivery Time</div>
                                    <div>
                                        <button className="text-black border hover:border-[rgba(222,12,0,.1)] hover:bg-[rgba(222,12,0,.1)] px-2 rounded">
                                            <svg style={{ fill: "#a3a3a3", stroke: "#a3a3a3", display: "inline-block", verticalAlign: "middle" }} width="12px" height="12px" version="1.1" viewBox="0 0 128 128" x="0px" y="0px"><path d="M126.905,112.061L68.668,11.191c-2.566-4.445-6.767-4.445-9.334,0L1.095,112.061c-2.567,4.445-0.466,8.082,4.668,8.082  h116.474C127.371,120.143,129.472,116.506,126.905,112.061z M68.742,39.112l-1.383,47.517H60.64l-1.383-47.517H68.742z   M63.902,106.781c-3.359,0-5.73-2.668-5.73-6.125c0-3.557,2.469-6.125,5.926-6.125c3.459,0,5.731,2.568,5.731,6.125  C69.828,104.113,67.557,106.781,63.902,106.781z"></path></svg>
                                            <span className="pl-1">Report</span>
                                        </button>
                                    </div>
                                </div>
                            </div>
                            <div className="order-details-btn bg-[#eceaea] flex justify-center items-center py-1">
                                <svg style={{ fill: "#a3a3a3", stroke: "#a3a3a3", display: "inline-block", verticalAlign: "middle" }} width="18px" height="18px" version="1.1" viewBox="0 0 100 100" x="0px" y="0px"><g transform="translate(0,-952.36218)"><path style={{ opacity: "1", stroke: "none", strokeWidth: "3.33333324999999990px", strokeLinecap: "round", strokeLinejoin: "round", strokeMiterlimit: "4px", strokeOpacity: "1", strokeDasharray: "none", strokeDashoffset: "0px", marker: "none", visibility: "visible", display: "inline", overflow: "visible", enableBackground: "accumulate", fillOpacity: "1", }} d="M 50 8 C 26.804041 8 8 26.804041 8 50 C 8 73.195959 26.804041 92 50 92 C 73.195959 92 92 73.195959 92 50 C 92 26.804041 73.195959 8 50 8 z M 49.84375 34 A 2.0001999 2.0001999 0 0 1 51.46875 34.625 L 73.46875 58.625 A 2.0012983 2.0012983 0 0 1 70.53125 61.34375 L 50 38.9375 L 29.46875 61.34375 A 2.0012983 2.0012983 0 0 1 26.53125 58.625 L 48.53125 34.625 A 2.0001999 2.0001999 0 0 1 49.84375 34 z " transform="translate(0,952.36218)"></path></g></svg>
                                <span className="font-bold text-[#a3a3a3] order-details-text pl-2">View Details</span>
                            </div>
                        </div>
                    </div>

                </div>
                <div className="border flex-grow">
                    <div className="border-b p-3 flex justify-center hover:bg-[rgba(222,12,0,.1)] cursor-pointer">
                        <span><svg style={{ fill: "#e15656", stroke: "#e15656", display: "inline-block", verticalAlign: "middle" }} width="20px" height="20px" version="1.1" viewBox="0 0 512 512" x="0px" y="0px"><g><path d="M330.9,220.3v-39.4h-38.5c-6.3-64.3-44.7-112.4-92.6-112.4c-47.9,0-86.3,48.1-92.6,112.4H68.7v262.6h189.3   c-24.3-23.8-39.5-57-39.5-93.6C218.5,284,267.5,229.4,330.9,220.3z M199.8,106c26.6,0,49.6,32.8,55,74.9h-110   C150.2,138.7,173.2,106,199.8,106z"></path><path d="M349.6,256.2c-51.6,0-93.6,42-93.6,93.6s42,93.6,93.6,93.6c51.6,0,93.6-42,93.6-93.6S401.3,256.2,349.6,256.2z    M405.8,368.6h-37.5V406h-37.5v-37.5h-37.5v-37.5h37.5v-37.5h37.5v37.5h37.5V368.6z"></path></g></svg></span>
                        <span className="pl-2 text-[#e15656]">Add all items to Shopping Bag</span>
                    </div>
                    <div className="border-b text-center px-5">
                        <div className="font-bold text-[#615858]">
                            Address
                        </div>
                        <div>
                            Rampura, House#17 /1,mokbul tower(bata showroom er 3rd floor), DIT Road, Rampura, Dhaka 1219.
                        </div>
                    </div>
                    <div>
                        <div className="p-3 flex justify-between">
                            <div>Order Number</div>
                            <div><span>Delivery Date</span>,&nbsp;<span>Probable Delivery Time</span></div>
                        </div>
                        <div>
                            <div className="flex py-4 border-b border-t">
                                <div className="px-5">
                                    <img src="https://picsum.photos/seed/chaldalorder/50/50" alt="" />
                                </div>
                                <div>
                                    <div className="font-bold text-sm text-[#4b4b4b]">Lifebuoy Handwash Lemon Fresh Refill 170 ml</div>
                                    <div className="flex justify-between">
                                        <span>Pack Qty</span>
                                        <span>Order Qty</span>
                                        <span>Price</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    );
}

export default UserOrders;