import { __ } from "services/utils/Translator";
import EventBus from "services/utils/EventBus";

import "./BottomNav.scss";

const BottomNav = () => {
  return (
    <div className="BottomNav flex md:hidden w-[100%] h-[47px] justify-between fixed bottom-0">
      <div className="chat-btn flex items-center justify-center w-[60px] cursor-pointer">
        <svg
        className="chat"
          width="38px"
          height="38px"
          style={{
            display: "inline-block",
            verticalAlign: "middle",
          }}
          viewBox="0 0 100 100"
          version="1.1"
          x="0px"
          y="0px"
        >
          <path d="M88.272,59.79c4.025-4.372,6.228-9.787,6.228-15.38c0-13.711-13.026-24.866-29.038-24.866  c-6.555,0-12.925,1.918-18.018,5.394c-3.138-0.882-6.485-1.366-9.963-1.366c-17.634,0-31.98,12.269-31.98,27.348  c0,6.19,2.454,12.185,6.939,17.01L9.03,77.804c-0.248,0.719-0.067,1.516,0.467,2.057c0.382,0.388,0.897,0.596,1.424,0.596  c0.21,0,0.421-0.033,0.626-0.101l13.193-4.349c4.03,1.499,8.313,2.259,12.74,2.259c9.518,0,18.073-3.579,23.937-9.24  c1.281,0.159,2.641,0.24,4.045,0.24c4.001,0,7.865-0.683,11.499-2.029l11.828,3.896c0.205,0.067,0.416,0.101,0.626,0.101  c0.526,0,1.042-0.208,1.424-0.596c0.534-0.541,0.715-1.338,0.467-2.057L88.272,59.79z M25.527,72.025  c-0.437-0.173-0.918-0.188-1.362-0.04l-10.036,3.309l2.493-7.224c0.251-0.729,0.062-1.537-0.487-2.077  C11.856,61.78,9.5,56.426,9.5,50.918c0-12.874,12.552-23.348,27.98-23.348c3.408,0,6.675,0.513,9.698,1.448  c0.005,0.002,0.01,0.003,0.014,0.005c10.655,3.301,18.269,11.87,18.269,21.896c0,12.874-12.552,23.348-27.98,23.348  C33.307,74.266,29.285,73.512,25.527,72.025z M77.536,63.214c-0.447-0.148-0.929-0.134-1.364,0.041  c-3.36,1.334-6.964,2.011-10.71,2.011c-0.257,0-0.502-0.016-0.755-0.021c3.011-4.169,4.754-9.076,4.754-14.326  c0-10.509-6.972-19.647-17.165-24.224c3.925-2.036,8.481-3.151,13.166-3.151c13.806,0,25.038,9.36,25.038,20.866  c0,4.91-2.104,9.687-5.922,13.447c-0.549,0.541-0.738,1.349-0.487,2.077l2.117,6.138L77.536,63.214z M27.806,52.014  c0,1.657-1.343,3-3,3s-3-1.343-3-3c0-1.657,1.343-3,3-3S27.806,50.357,27.806,52.014z M40.48,52.014c0,1.657-1.343,3-3,3  c-1.657,0-3-1.343-3-3c0-1.657,1.343-3,3-3C39.137,49.014,40.48,50.357,40.48,52.014z M53.155,52.014c0,1.657-1.343,3-3,3  c-1.657,0-3-1.343-3-3c0-1.657,1.343-3,3-3C51.812,49.014,53.155,50.357,53.155,52.014z"></path>
        </svg>
      </div>
      <div className="place-order-btn text-white text-[22px] flex items-center justify-center flex-grow cursor-pointer">
       Place Order
      </div>
      <div className="shopping-cart-btn flex items-center justify-center w-[60px] cursor-pointer" onClick={() => EventBus.dispatch("showCartOnMobile")}>
        <svg
        className="shopping-bag"
          version="1.1"
          id="Calque_1"
          x="0px"
          y="0px"
          width="21px"
          height="32px"
          viewBox="0 0 100 160.13"
        >
          <g>
            <polygon points="11.052,154.666 21.987,143.115 35.409,154.666  "></polygon>
            <path d="M83.055,36.599c-0.323-7.997-1.229-15.362-2.72-19.555c-2.273-6.396-5.49-7.737-7.789-7.737   c-6.796,0-13.674,11.599-16.489,25.689l-3.371-0.2l-0.19-0.012l-5.509,1.333c-0.058-9.911-1.01-17.577-2.849-22.747   c-2.273-6.394-5.49-7.737-7.788-7.737c-8.618,0-17.367,18.625-17.788,37.361l-13.79,3.336l0.18,1.731h-0.18v106.605l17.466-17.762   l18.592,17.762V48.06H9.886l42.845-10.764l2.862,0.171c-0.47,2.892-0.74,5.865-0.822,8.843l-8.954,1.75v106.605l48.777-10.655   V38.532l0.073-1.244L83.055,36.599z M36.35,8.124c2.709,0,4.453,3.307,5.441,6.081c1.779,5.01,2.69,12.589,2.711,22.513   l-23.429,5.667C21.663,23.304,30.499,8.124,36.35,8.124z M72.546,11.798c2.709,0,4.454,3.308,5.44,6.081   c1.396,3.926,2.252,10.927,2.571,18.572l-22.035-1.308C61.289,21.508,67.87,11.798,72.546,11.798z M58.062,37.612l22.581,1.34   c0.019,0.762,0.028,1.528,0.039,2.297l-23.404,4.571C57.375,42.986,57.637,40.234,58.062,37.612z M83.165,40.766   c-0.007-0.557-0.01-1.112-0.021-1.665l6.549,0.39L83.165,40.766z"></path>
          </g>
        </svg>
      </div>
    </div>
  );
};

export default BottomNav;
