import React from "react";
import App from "./App";
import { BrowserRouter as Router } from "react-router-dom";
import { createRoot } from "react-dom/client";
import "./index.css";

const domElm = document.getElementById("root");

const root = createRoot(domElm);

root.render(
  <Router>
    <App />
  </Router>
);
