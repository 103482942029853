import Product from "components/Products/Product";
import "./Products.scss";
import React from "react";
import ApiClient from "Api/ApiClient";

class Products extends React.Component {
  constructor(){
    super()
    this.state = {products:[]}
  }

  componentDidMount() {
    ApiClient.get("products").then((response) => {
      this.setState({products:response.data.data});
    })
  }

  render(){
    return this.state.products.map(
      (product,index) => {
        return (  
          <Product key={index} product={product}></Product>
        );
      }
    );
  }
}

export default Products;
