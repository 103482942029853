const AddAddress = () => {
    return (<div>
        <div className="text-xl text-[#615e58]">
            New Address
        </div>

        <form className="w-[600px] text-[#615e58]">
            <div className="flex flex-col">
                <label htmlFor="street" className="bg-white p-1 ml-4 transform translate-y-[15px] !inline-block self-start">Street Address <span className="text-red-500">*</span></label>
                <textarea name="street" id="" cols="30" rows="3" className="rounded-lg focus:outline-0 focus:ring-0"></textarea>

            </div>
            <div className="flex flex-col">
                <label htmlFor="area" className="bg-white p-1 ml-4 transform translate-y-[15px] !inline-block self-start">Area <span className="text-red-500">*</span></label>

                <div className="rounded-lg py-3 border border-gray-500 cursor-pointer">
                    <div className="flex justify-between items-center px-3 w-100">
                        <span>Area</span>
                        <span className="flex items-center">

                        <span className="p-[3px] border-r-[3px] border-b-[3px] border-[#c9c9c9] transform -rotate-45 self-start box-border"></span>
                        </span>
                    </div>
                </div>
                {/* <input type="text" name="" id="" className="rounded-lg py-3 focus:outline-0 focus:ring-0" /> */}
            </div>
            <div className="flex">
                <div className="flex-grow flex flex-col pr-1">
                    <label htmlFor="floor" className="bg-white p-1 ml-4 transform translate-y-[15px] !inline-block self-start">Floor No.</label>
                    <input type="text" name="floor" id="" className="rounded-lg py-3 focus:outline-0 focus:ring-0" />
                </div>
                <div className="flex-grow flex flex-col pl-1">
                    <label htmlFor="apartment" className="bg-white p-1 ml-4 transform translate-y-[15px] !inline-block self-start">Apartment No.</label>
                    <input type="text" name="apartment" id="" className="rounded-lg py-3 focus:outline-0 focus:ring-0" />
                </div>
            </div>
            <div className="flex flex-col">
                <label htmlFor="name" className="bg-white p-1 ml-4 transform translate-y-[15px] !inline-block self-start">Name <span className="text-red-500">*</span></label>
                <input type="text" name="name" id="" className="rounded-lg py-3 focus:outline-0 focus:ring-0" />
            </div>

            <div className="flex flex-col">
                <label htmlFor="phone" className="bg-white p-1 ml-4 transform translate-y-[15px] !inline-block self-start">Phone Number <span className="text-red-500">*</span></label>
                <input type="text" name="" id="" className="rounded-lg py-3 focus:outline-0 focus:ring-0" />
            </div>
            <div className="pin-location flex justify-center py-5">
                <iframe src="https://www.google.com/maps/embed?pb=!1m10!1m8!1m3!1d233774.59936698672!2d90.50996290703124!3d23.721401359112733!3m2!1i1024!2i768!4f13.1!5e0!3m2!1sen!2sbd!4v1650362126643!5m2!1sen!2sbd" width="600" height="150" style={{ border: "0", borderRadius: "12px" }}></iframe>
                <div className="pin-btn border bg-white w-[80%] border-[#ff666b] cursor-pointer flex justify-center items-center py-2 rounded-md">
                    <svg style={{ display: "inline-block", verticalAlign: "middle" }} width="20px" height="20px" viewBox="0 0 15.669 20.327"><path fill="#ff666b" d="M7.834 0A7.843 7.843 0 000 7.834c0 2.43 1.257 5.252 3.737 8.387a33.353 33.353 0 003.678 3.947.635.635 0 00.841 0 33.353 33.353 0 003.678-3.947c2.479-3.136 3.737-5.958 3.737-8.387A7.843 7.843 0 007.834 0zm0 18.825C6.325 17.387 1.27 12.24 1.27 7.834a6.564 6.564 0 0113.128 0c0 4.405-5.054 9.552-6.564 10.991z"></path><path fill="#ff666b" d="M9.771 4.09L5.4.158a.6.6 0 00-.813 0L.218 4.09a.678.678 0 00-.068.924.6.6 0 00.88.071l.53-.477v3.257a.64.64 0 00.624.655H7.8a.64.64 0 00.624-.655V4.608l.53.477a.6.6 0 00.88-.071.678.678 0 00-.068-.924zM7.179 7.209h-4.37V3.485l2.185-1.966 2.185 1.966z" data-name="Vector" transform="translate(2.84 3.418)"></path></svg>
                    <span className="text-[#ff666b] pl-2">Pin on Map</span>
                </div>
            </div>
            <div className="mt-7 py-1 cursor-pointer flex justify-center items-center text-white bg-[#ff686e] hover:bg-[#ff4e56] rounded-lg">
                <button type="submit" className="uppercase">Save Address</button>
            </div>
        </form>
    </div>);
}

export default AddAddress;