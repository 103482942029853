import "components/styles/Home.scss";
import React from "react";
import HowToOrderSlider from "components/Sliders/HowToOrder/HowToOrder";
import BannerBackground from "assets/img/landingBannerTop.jpg";
import Amex from "assets/img/pay_opts/Amex.png";
import bkash from "assets/img/pay_opts/bkash.png";
import MasterCard from "assets/img/pay_opts/mastercard.png";
import Visa from "assets/img/pay_opts/VIsa.png";
import COD from "assets/img/pay_opts/COD.png";
import CODFeat from "assets/img/cash-on-delivery.png";
import OneHour from "assets/img/1-hour.png";
import Playstore from "assets/img/google_play_store.png";
import Appstore from "assets/img/app_store.png";
import PhoneIcon from "assets/img/phone_icon.png";
import FacebookIcon from "assets/img/social_icons/Facebook.png";
import YoutubeIcon from "assets/img/social_icons/Youtube.png";
import InstagramIcon from "assets/img/social_icons/Instagram.png";
import TwitterIcon from "assets/img/social_icons/Twitter.png";
import { Link } from "react-router-dom";
import logo from "assets/img/branding/logo-inverted.png";
import AppConfig from "config/app";
import App from "App";

const Home = () => {

  let homeCategories = [
    "Fruits and Vegetables",
    "Meat and Fish",
    "Cooking",
    "Beverages",
    "Home and Cleaning",
    "Pest Control",
    "Stationery & Office",
    "Beauty Products",
    "Health Products",
    "Pet Care",
    "Kitchen Appliances",
    "Baby Care"
  ];


  return (
    <div className="Home">

      {/* Header Big Search */}
      <section>
        <div className="flex justify-center items-center h-[325px]" style={{ background: "url(" + BannerBackground + ") no-repeat center", backgroundSize: "cover" }}>
          <div className="w-[90%] mlg:w-[70%] flex flex-col justify-center items-center">
            <div className=" w-[100%] text-center whitespace-nowrap md:will-change-auto text-[22px] sm:text-[30px] md:text-[30px] mlg:text-[40px] text-black font-bold py-3">
              Groceries delivered in 30 minutes
            </div>
            <div className="w-[100%] relative">
              <input
                type="text"
                placeholder={AppConfig.searchbar_placeholder}
                className="rounded-md w-[100%]"

              />
              <button
                type="submit"
                className="bg-white w-[35px] h-[35px] text-gray-600 absolute right-1 top-1"
              >
                <svg className="search" version="1.1" x="0px" y="0px" viewBox="0 0 100 100"><path d="M44.5,78.5c-18.8,0-34-15.3-34-34s15.3-34,34-34s34,15.3,34,34S63.3,78.5,44.5,78.5z M44.5,18.1  C30,18.1,18.2,30,18.2,44.5S30,70.8,44.5,70.8S70.9,59,70.9,44.5S59,18.1,44.5,18.1z"></path><path d="M87.2,91c-1,0-2-0.4-2.7-1.1L63.1,68.5c-1.5-1.5-1.5-3.9,0-5.4s3.9-1.5,5.4,0l21.3,21.3  c1.5,1.5,1.5,3.9,0,5.4C89.2,90.6,88.2,91,87.2,91z"></path></svg>
              </button>
            </div>
          </div>
        </div>
      </section>


      {/* Home Page Ads */}
      <section>
        <div className="flex justify-center mt-5">
          <div className="flex flex-col md:flex-row">
            <div className="mx-2 md:my-0 md:mx-0 md:mr-1">
              <img
                src="https://picsum.photos/seed/chaldal-offer/588/160"
                alt=""
              />
            </div>
            <div className="mx-2 my-2 md:my-0 md:mx-0 md:ml-1">
              <img
                src="https://picsum.photos/seed/chaldal-offer/588/160"
                alt=""
              />
            </div>
          </div>
        </div>
      </section>


      {/* Product Categories Menu */}
      <section>
        <div className="flex flex-col items-center mt-4 mb-12">
          <div className="text-xl md:text-3xl pt-5 pb-9">আমাদের পণ্যের বিভাগ সমূহ</div>
          <div className="category-grid px-3">
            {homeCategories.map((category, index) => {
              return (
                <React.Fragment key={index}>
                  <div className="border p-4 rounded flex justify-between cursor-pointer">
                    <span className="">{category}</span>
                    <span>
                      <i className="bi bi-arrow-right"></i>
                    </span>
                  </div>
                </React.Fragment>
              );
            })}
          </div>
        </div>
      </section>


      {/* How to order Slider */}
      <section>
        {/* <HowToOrderSlider /> */}
      </section>



      {/* Special Offers */}
      <section>
      </section>


      {/* Why People Like Us */}
      <section>
      </section>


      {/* Customers Testimonials */}
      <section>
      </section>

      <footer className="pb-12">
        <div className="flex flex-col items-center md:flex-row md:justify-between px-5 py-2 bg-[#f2f2f2] text-[#606060]">
          <div className="flex flex-col items-center md:flex-row md:items-center ">
            <span className="flex items-center"><img src={OneHour} alt="" className="h-[40px] w-[40px]" />30 minute delivery</span>
            <span className="flex items-center"><img src={CODFeat} alt="" className="h-[40px] w-[40px]" />Cash on delivery</span>
          </div>
          <div className="flex items-center">
            <span>Pay with</span>
            <span><img src={Amex} alt="" className="h-[40px] w-[40px]" /></span>
            <span><img src={MasterCard} alt="" className="h-[40px] w-[40px]" /></span>
            <span><img src={Visa} alt="" className="h-[40px] w-[40px]" /></span>
            <span><img src={bkash} alt="" className="h-[40px] w-[40px]" /></span>
            <span><img src={COD} alt="" className="h-[40px] w-[40px]" /></span>
          </div>
        </div>
        <div className="flex flex-col mlg:flex-row">
          <div className="border-0 md:border-r">
            <div className="text-center md:text-left p-4">
              <Link to="/" className="inline-block pb-2">
                <img className="" src={logo} alt="" style={{ height: "70px" }} />
              </Link>
              <p className="pb-7">{AppConfig.long_desc}</p>
            </div>
            <div className="flex flex-col md:flex-row p-0 md:p-2 text-center md:text-left md:justify-between text-[#615e58]">
              <div className="md:mx-2 my-4 md:my-0">
                <div className="font-semibold text-xl pb-2 border-b">Customer Service</div>
                <ul>
                  <li>Contact Us</li>
                  <li>FAQ</li>
                </ul>
              </div>
              <div className="md:mx-2 my-4 md:my-0">
                <div className="font-semibold text-xl pb-2 border-b tex">About {AppConfig["app_name"]}</div>
                <ul>
                  <li>Privacy Policy</li>
                  <li>Terms of Use</li>
                </ul>
              </div>
              <div className="md:mx-2 my-4 md:my-0">
                <div className="font-semibold text-xl pb-2 border-b tex">For Business</div>
                <ul>
                  <li>Corporate</li>
                </ul>
              </div>
            </div>
          </div>
          <div className="md:min-w-[380px] p-3">
            <div className="">
              <div className="flex justify-center md:justify-between">
                <img alt="" src={Playstore} className="mr-3 md:pr-1 max-w-[45%]" />
                <img alt="" src={Appstore} className="ml-3 md:pl-1 max-w-[45%]" />
              </div>
              <div className="py-4">
                <div className="contact-section flex flex-col items-center md:items-end text-[#615e58]" >
                  <div className="flex items-center">
                    <img src={PhoneIcon} alt="" className="h-[26px] w-[26px] inline-block" />
                    <span className="text-[25px] font-bold">
                     {AppConfig.hotline_number}
                    </span>
                  </div>
                  <div className="">
                    or email<span className="font-bold">&nbsp;{AppConfig.support_email}</span>
                  </div>
                </div>
              </div>
              <div className="flex justify-center md:justify-end">
                <div className="flex">
                  <img src={FacebookIcon} alt="" className="h-[40px] w-[40px] mx-1 cursor-pointer" />
                  <img src={YoutubeIcon} alt="" className="h-[40px] w-[40px] mx-1 cursor-pointer" />
                  <img src={InstagramIcon} alt="" className="h-[40px] w-[40px] mx-1 cursor-pointer" />
                  <img src={TwitterIcon} alt="" className="h-[40px] w-[40px] mx-1 cursor-pointer" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </footer>
    </div>
  );
};

export default Home;
