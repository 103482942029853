import Home from "pages/Home";
import Help from "pages/Help";
import Categories from "pages/Categories";
import Offers from "pages/Offers";
import UserProfile from "pages/UserProfile";
import SearchResult from "pages/SearchResult";
import SelectLocation from "pages/SelectLocation";
import ChangePassword from "pages/ChangePassword";
import UserOrders from "pages/UserOrders";
import Products from "pages/Products";
import NotFound from "pages/NotFound";
import PaymentHistory from "pages/PaymentHistory";

import { Routes, Route } from "react-router-dom";
import SignInModal from "./Modals/Signin/SignIn";
import PinLocation from "./Modals/PinLocation/PinLocation";
import AddAddress from "./Modals/AddAddress/AddAddress";
import SelectArea from "./Modals/SelectArea/SelectArea";


function Mainbar() {
  return (
    <Routes>
      <Route path="/" element={<Home />} />
      <Route path="products" element={<Products />} />
      <Route path="help/*" element={<Help />} />
      <Route path="categories/*" element={<Categories />} />
      <Route path="offers" element={<Offers />} />
      <Route path="search/*" element={<SearchResult />} />
      <Route path="select-city" element={<SelectLocation />} />
      <Route path="user-profile" element={<UserProfile />} />
      <Route path="user-orders" element={<UserOrders />} />
      <Route path="change-password" element={<ChangePassword />} />
      <Route path="payment-history" element={<PaymentHistory />} />

      {/* Test Routes */}
      <Route path="modals/signin" element={<SignInModal/>}></Route>
      <Route path="modals/add-address" element={<AddAddress/>}></Route>
      <Route path="modals/pin-location" element={<PinLocation/>}></Route>
      <Route path="modals/select-area" element={<SelectArea/>}></Route>
      <Route path="*" element={<NotFound />} />
    </Routes>
  );
}

export default Mainbar;
