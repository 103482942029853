import { Link } from "react-router-dom";
import FlagsImg from "assets/img/flags.png"
import React from "react";
import ApiClient from "Api/ApiClient";

class SignInModal extends React.Component {

  constructor(props) {
    super(props)
    this.state = { account: { email: '', mobile: '', password: '', confirm_password: '' } }

    this.handleChange = this.handleChange.bind(this);
    this.handleSignIn = this.handleSignIn.bind(this);
  }


  handleChange({ currentTarget: input }) {
    const account = { ...this.state.account };
    account[input.name] = input.value;
    this.setState({ account });
  }

  handleSignIn(e) {
    e.preventDefault();

    ApiClient.get("sanctum/csrf-cookie").then(() => {
      ApiClient.post('login', {
        email: this.state.account.email,
        password: this.state.account.password,
      })
        .then(function (response) {
          
        })
        .catch(function (error) {
          console.log(error);
        });
    })

  }

  render() {

    const { email, password, confirm_password, mobile } = this.state;
    const { signInModalClose } = this.props;


    return (
      <div className="w-[400px] border-2 bg-white rounded-md" style={{ boxShadow: "0 14px 45px rgb(0 0 0 / 25%)" }}>
        <div className="px-5 pt-4 pb-1">
          <div className="flex justify-between mb-1">
            <span className="text-xl">Login</span>
            <button onClick={signInModalClose} className="signin-close"></button>
          </div>
        </div>

        <div className="px-5 pb-5">
          <div className="mb-4">
            <div className="bg-[#49639f] cursor-pointer border border-[#3e4e7c] hover:bg-[#364472] mb-1 text-white rounded shadow-md font-[14px] h-[58px] flex justify-center items-center">
              <svg
                width="20px"
                height="20px"
                style={{
                  fill: "white",
                  stroke: "white",
                  display: "inline-block",
                  verticalAlign: "middle",
                }}
                viewBox="0 0 430.113 430.114"
              >
                <path
                  id="Facebook"
                  d="M158.081,83.3c0,10.839,0,59.218,0,59.218h-43.385v72.412h43.385v215.183h89.122V214.936h59.805 c0,0,5.601-34.721,8.316-72.685c-7.784,0-67.784,0-67.784,0s0-42.127,0-49.511c0-7.4,9.717-17.354,19.321-17.354 c9.586,0,29.818,0,48.557,0c0-9.859,0-43.924,0-75.385c-25.016,0-53.476,0-66.021,0C155.878-0.004,158.081,72.48,158.081,83.3z"
                ></path>
              </svg>
              <span className="ml-3">ফেসবুকের মাধ্যমে লগইন করুন</span>
            </div>
            <div className="email-login-btn cursor-pointer rounded shadow-md border font-[14px] h-[58px] flex justify-center items-center">
              <svg
                style={{
                  display: "inline-block",
                  verticalAlign: "middle",
                }}
                width="25px"
                height="25px"
                version="1.1"
                id="Layer_1"
                x="0px"
                y="0px"
                viewBox="0 0 491.52 491.52"
              >
                <rect
                  y="85.914"
                  style={{ fill: "#F6C358" }}
                  width="491.52"
                  height="319.693"
                ></rect>
                <polygon
                  style={{ fill: "#FCD462" }}
                  points="245.76,217.258 491.52,405.604 0,405.604 "
                ></polygon>
                <polygon
                  style={{ fill: "#DC8744" }}
                  points="245.76,291.673 0,85.916 491.52,85.916 "
                ></polygon>
                <polygon
                  style={{ fill: "#FCD462" }}
                  points="245.76,274.261 0,85.916 491.52,85.916 "
                ></polygon>
              </svg>
              <span className="ml-3">ইমেইল দিয়ে লগইন করুন</span>
            </div>
          </div>

          <div className="orContainer">
            <span className="bg-white rounded font-[14px] flex justify-center items-center">অথবা</span>
          </div>

          {/* Login With Phone */}
          <section className="hidden">
            <div className="bg-white rounded font-[14px] my-7 flex justify-center items-center">
              আপনার মোবাইল ফোন নাম্বার ব্যবহার করুন
            </div>
            <div className="mb-3 flex items-center">
              <span className="h-[32px] w-[32px]" style={{ backgroundImage: "url(" + FlagsImg + ")", backgroundPosition: "-96px -32px" }}>
              </span>
              <div className="ml-1 flex-grow">
                <input type="text" placeholder="+88" pattern="[0-9]{11}" className="rounded-none w-[100%] border-b-2 border-b[#c9c9c9] focus:border-b-[#ff7517] border-t-0 border-l-0 border-r-0 focus:ring-0" />
                <div className="text-center text-xs text-[#464646] ">যেমন +8801672955886</div>
              </div>
            </div>
          </section>


          {/* Login With E-mail */}
          <section className="">
            <form className="email-login pt-1 pb-7">
              <div className="pb-7">
                <label htmlFor="email">Email Address</label>
                <input value={email} onChange={this.handleChange} autoComplete="off" className="w-[100%] border-b-2 border-t-0 border-l-0 border-r-0 focus:ring-0" type="email" name="email" id="email" />
              </div>
              <div>
                <label htmlFor="password">Password</label>
                <input value={password} onChange={this.handleChange} autoComplete="off" className="w-[100%] border-b-2 border-t-0 border-l-0 border-r-0 focus:ring-0" type="password" name="password" id="password" />
              </div>
            </form>
          </section>

          <div className="signin-btn cursor-pointer text-white rounded shadow-md border font-[14px] h-[58px] flex justify-center items-center" style={{ boxShadow: "0 1px 6px rgb(0 0 0 / 50%)" }}>
            <button className="uppercase" onClick={this.handleSignIn}>সাইন আপ / লগ ইন</button>
          </div>
          <div className="text-xs font-normal text-center text-[#464646] my-3">
            This site is protected by reCAPTCHA and the Google <Link to="/help/privacy-policy"><span className="text-sky-600">Privacy Policy</span></Link> and
            <Link to="/help/terms-of-use"><span className="text-sky-600">Terms of Service</span></Link> apply.
          </div>
        </div>
      </div>
    );
  }


};

export default SignInModal;
