const UserProfile = () => {
    return (
        <div>
            Your Profile
            <form>
                <div className="flex flex-col">
                    <label htmlFor="name">Name</label>
                    <input type="text" name="name" id="" />
                </div>

                <div className="flex flex-col">

                    <label htmlFor="email">Email Address</label>
                    <input type="text" name="email" id="" />
                </div>
                <div className="flex flex-col">
                    <label htmlFor="gender">Gender</label>
                    <select name="gender" id="">
                        <option value="male">Male</option>
                        <option value="female">Female</option>
                        <option value="others">Others</option>
                    </select>
                </div>

                <div className="flex flex-col">
                    <label htmlFor="dob">Date of Birth (YYYY-MM-DD)</label>
                    <input type="text" name="dob" id="" />
                </div>
            </form>

            <div className="border">
                <div className="border-b p-4">
                    <svg style={{ fill: "#214354", stroke: "#21435", display: "inline-block", verticalAlign: "middle" }} width="25px" height="25px" x="0px" y="0px" viewBox="0 0 500 500"><g><path d="M418.455,188.455C418.455,95.418,343.037,20,250.001,20   C156.964,20,81.546,95.418,81.546,188.455c0,33.983,10.074,65.614,27.383,92.079h-0.298l126.278,201.831h0.005   c2.759,5.539,8.479,9.349,15.087,9.349c6.607,0,12.327-3.811,15.085-9.349h0.006l126.279-201.831h-0.299   C408.382,254.068,418.455,222.438,418.455,188.455L418.455,188.455 M250.001,111.641c42.425,0,76.814,34.389,76.814,76.814   c0,42.426-34.389,76.814-76.814,76.814s-76.815-34.389-76.815-76.814C173.187,146.03,207.575,111.641,250.001,111.641   L250.001,111.641 M250.001,111.641L250.001,111.641z"></path></g></svg>
                    <span className="font-bold text-[#4b4b4b]">Select a Delivery Address</span>
                </div>
                <div className="add-new-address m-4 cursor-pointer flex justify-center items-center text rounded-md py-2 border border-[#b7b7b7] cursor:pointer hover:border-[#ff686e] text-[#1d3a4b] hover:text-[#ff686e]">
                    <span>
                        <svg width="15px" height="15px" style={{ display: "inline-block", verticalAlign: "middle" }} version="1.1" viewBox="5 7 22 22" x="0px" y="0px"><g stroke-width="1"><rect height="19" width="1" x="16" y="7"></rect><rect height="1" width="19" x="7" y="16"></rect></g></svg>
                        <span className="pl-2">Add New Address</span>
                    </span>
                </div>

                <div className="flex m-4 justify-between bg-[#e6fdf0] border border-[#8abdaa] hover:bg-[#fdfffe] rounded-md">
                    <span className="flex">
                        <span className="p-3">

                    <svg style={{ fill: "#3BB07E", stroke: "#3BB07E", display: "inline-block", verticalAlign: "middle" }} width="20px" height="20px" version="1.1" viewBox="0 0 100 100" x="0px" y="0px"><path d="M50,88.6c21.3,0,38.6-17.3,38.6-38.6S71.3,11.4,50,11.4S11.4,28.7,11.4,50S28.7,88.6,50,88.6z M31.8,45.9l12.3,12.3  l22.5-22.5l3,3L44.1,64.2L28.8,48.9L31.8,45.9z"></path></svg>
                        </span>
                    <span className="flex flex-col py-1 justify-between text-[#615e58]">
                        <span>Address Line</span>
                        <span className="text-xs font-bold ">Address City</span>
                    </span>
                    </span>
                    <span className="text-[#ff686e] font-bold hover:underline cursor-pointer flex items-center px-4">
                        delete
                    </span>
                </div>
            </div>
        </div>);
}

export default UserProfile;