import { useLocation, Link } from "react-router-dom";

const Categories = () => {
  const location = useLocation();
  const categories = location.state.categories;
  const breadcumb = location.state.breadcumb;

  return (
    <div className="mx-4">
      <div className="breadcumb py-5">
        {breadcumb.map((breadcumElement, index) => {
          return (
            <span key={index} className="breadcumb__element">
              {breadcumElement}
            </span>
          );
        })}
      </div>

      <div className="flex flex-wrap">
        {Object.keys(categories).map((category, index) => {
          return (
            <Link
              to={{
                pathname: "/categories/" + category,
              }}
              state={{
                categories: categories[category],
                breadcumb: [...breadcumb, category],
              }}

              key={index}
            >
              <div

                className="w-[200px] h-[250px] cursor-pointer text-center m-2 p-2 hover:underline"
              >
                <img
                  src="https://picsum.photos/seed/chaldalcaffdf/200/200"
                  alt=""
                />
                {category}
              </div>
            </Link>
          );
        })}
      </div>
    </div>
  );
};

export default Categories;
