import ApiClient from "Api/ApiClient";
import React from "react";
import StringUtils from "services/utils/String";

class Categories extends React.Component {
    constructor() {
        super();
        this.state = { categories: [], activeIndex: null };
    }

    changeActiveIndex = (index) => {
        this.setState({ activeIndex: index });
    };

    componentDidMount() {
        ApiClient.get("product/categories").then((response) => {
            console.log(response.data.data);
            this.setState({ categories: response.data.data });
        });
    }

    render() {
        return (
            <ul className="text-[13px] category-tree ml-3 mb-7">
                {
                    this.state.categories.map((category, index) => {
                        return (
                            <li key={index} className="">
                                <span className="category-item py-1 flex items-center justify-between">
                                    <span className="flex">
                                        <img className="pr-3" src={category.icon_url} style={{height:"20px"}} alt="" />
                                        <span>{StringUtils.limit(category.name, 20, "...")}</span>
                                    </span>
                                    <span className="cat-caret"></span>
                                </span>
                                <ul className="ml-2 pl-4 border-l">
                                    {category.subcategories.map((subcategory, index) => {
                                        return (
                                            <li key={index}>
                                                <span className="block py-1 category-item">{StringUtils.limit(subcategory.name, 20, "...")}</span>
                                                <ul className="pl-4 border-l">
                                                    {subcategory.subsubcategories.map((subsubcategory, index) => {
                                                        return (
                                                            <li key={index}>
                                                                <span className="block py-1 category-item">{StringUtils.limit(subsubcategory.name, 20, "...")}
                                                                </span>
                                                            </li>
                                                        );
                                                    })}
                                                </ul>
                                            </li>
                                        );
                                    }
                                    )}
                                </ul>
                            </li>
                        );
                    })
                }
            </ul>
        );
    }
}

export default Categories;
