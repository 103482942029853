import React from "react";



class Product extends React.Component{

  constructor(props){
    super()
    this.product = props.product;
  }


  componentDidMount(){
    console.log(this.props)
  }

    render(){
     return ( <div className="Product m-1">
        <div className="Product__details">
          <div>
            <img
              alt="product"
              src={this.product.img_url}
            />
          </div>
          <div>{this.product.title}</div>
          <div>
            <span className="">{this.product.unit_value}</span>
            &nbsp;
            <span className="capitalize">{this.product.unit_type}</span>
          </div>
          <div className="">
            <span className="text-sm text-red-600 font-semibold pr-2">&#2547;&nbsp;Current</span>
            <span className="line-through text-xs">Old</span>
          </div>

          <div className="Product__details__overlay">
            <div className="Product__details__overlay__info hidden">
              <div className="text-white font-bold">&#2547;&nbsp;Price</div>
              <div className="Product__details__overlay__info__qtys">
                <span className="Product__details__overlay__info__qtys__inc-btn">
                  -
                </span>
                <span className="Product__details__overlay__info__qtys__qty">
                  3
                </span>
                <span className="Product__details__overlay__info__qtys__dec-btn">
                  +
                </span>
              </div>
              <div className="text-white font-bold">in bag</div>
            </div>
            <div className="flex flex-col text-3xl text-white items-center justify-center p-5 text-center">
            বাজারের ব্যাগে যোগ করুন
            </div>
            <div className="Product__details__overlay__btn">
              <span>
                Details <i className="bi bi-arrow-right-short"></i>
              </span>
            </div>
          </div>
        </div>
        <div className="Product__atc-btn hidden">
          <button>
            <i className="bi bi-lightning-fill"></i> Add to bag
          </button>
        </div>
        <div className="Product__incdec">
        <button className="Product__incdec__dec">-</button>
        <button className="Product__incdec__total">5 in bag</button>
        <button className="Product__incdec__inc">+</button>
        </div>
      </div>
      );
    }
}

export default Product;