import React, { useState } from "react";
import { Link } from "react-router-dom";
import LocationDrawer from "../LocationDrawer/LocationDrawer";
import SignInModal from "../Modals/Signin/SignIn";
import MoreModal from "components/Modals/More/More";
import EventBus from "services/utils/EventBus";
import AppConfig from "config/app";

import "./Header.scss";
import logo from "assets/img/branding/logo.png";

function Header() {

  const [ShowSignInModal, setShowSignInModal] = useState(false);
  const [ShowMoreModal, setShowMoreModal] = useState(false);
  const [HamburgNotCross, setHamburgNotCross] = useState(true);

  const signInBtnClickHandler = () => {
    setShowSignInModal(true);
  }

  const signInModalClose = () => {
    setShowSignInModal(false)
  }


  const moreBtnClickHandler = () => {
    setShowMoreModal(true);
  }

  const moreModalClose = () => {
    setShowMoreModal(false);
  }

  const mobileHamburgClicked = () => {
    EventBus.dispatch("hamburgClickedOnMobile");
    setHamburgNotCross(!HamburgNotCross);
  }

  const desktopHamburgClicked = () =>{
    EventBus.dispatch("hamburgClickedOnDesktop")
  }

  return (
    <React.Fragment>
      <div className="Header">
        <div className="layer-1 px-2 md:px-0 flex items-center justify-between h-[55px] font-bold text-white">

          {/* hamburg Icon:Desktop */}
          <div className="px-5 hidden md:block" onClick={ desktopHamburgClicked }>
            <svg
            className="hamburg"
              width="25px"
              height="25px"
              version="1.1"
              viewBox="0 0 100 100"
              style={{
                display: "inline-block",
                verticalAlign: "middle",
              }}
            >
              <path d="m12 20v8h76v-8zm0 26v8h76v-8zm0 26v8h76v-8z"></path>
            </svg>
          </div>

          {/* hamburg Icon:Mobile */}
          <div className={"mobile-hamburg flex flex-col md:hidden pl-2 pr-3 " + (HamburgNotCross ? "" : "hidden")} onClick={() => { mobileHamburgClicked() }}>
            <div className="h-[2px] w-[25px] mb-[7px] mobile-hamburg__bar"></div>
            <div className="h-[2px] w-[25px] mb-[7px] mobile-hamburg__bar"></div>
            <div className="h-[2px] w-[25px] mobile-hamburg__bar"></div>
          </div>

          {/* Cross Icon:Mobile */}
          <div className={"hamburg-cross flex flex-col md:hidden pl-2 pr-3 " + (HamburgNotCross ? "hidden" : "")} onClick={() => { mobileHamburgClicked() }}>
            <div className="h-[2px] w-[25px] hamburg-cross__item trasnform rotate-[45deg] translate-y-[2px]"></div>
            <div className="h-[2px] w-[25px] hamburg-cross__item opacity-0 "></div>
            <div className="h-[2px] w-[25px] hamburg-cross__item trasnform rotate-[315deg] translate-y-[-2px]"></div>
          </div>


          {/* Branding Logo */}
          <Link to="/" className="hidden md:flex items-center">
            <img className="pr-5" src={logo} alt="" style={{ height: "40px" }} />
          </Link>

          {/* Search Field */}
          <div className="flex-grow h-[100%] pr-3">
            <form className="search flex items-center h-[100%] w-[100%] relative">
              
              <input
                type="text"
                placeholder={AppConfig.searchbar_placeholder}
                className="form-input header-search border-none focus:ring-0 h-[40px] w-[100%] text-gray-800 text-[14px]"
              />
              <button
                type="submit"
                className="bg-white h-[38px] flex justify-center items-center absolute right-2"
              >
                <svg
                  className="search inline-block mt-[2px]"
                  version="1.1"
                  x="0px"
                  y="0px"
                  viewBox="0 0 100 100"
                  style={{
                    width: "30px",
                    height: "30px",
                  }}
                >
                  <path d="M44.5,78.5c-18.8,0-34-15.3-34-34s15.3-34,34-34s34,15.3,34,34S63.3,78.5,44.5,78.5z M44.5,18.1  C30,18.1,18.2,30,18.2,44.5S30,70.8,44.5,70.8S70.9,59,70.9,44.5S59,18.1,44.5,18.1z"></path>
                  <path d="M87.2,91c-1,0-2-0.4-2.7-1.1L63.1,68.5c-1.5-1.5-1.5-3.9,0-5.4s3.9-1.5,5.4,0l21.3,21.3  c1.5,1.5,1.5,3.9,0,5.4C89.2,90.6,88.2,91,87.2,91z"></path>
                </svg>
              </button>
            </form>
          </div>

          {/* Header menu */}
          <div className="menu flex h-[100%]">

            {/* Location Menu */}
            <div className="location-menu hidden md:flex items-center px-2 relative">
              <span className="inline-block px-2">
                <svg
                  className="home"
                  style={{
                    display: "inline-block",
                    verticalAlign: "middle",
                  }}
                  width="20px"
                  height="17px"
                  fill="none"
                  viewBox="0 0 62 60"
                >
                  <path
                    d="M61.2 29.274a2.955 2.955 0 00-.243-4.229L33.26.81c-1.257-1.1-3.272-1.077-4.503.052L.964 26.338A2.938 2.938 0 00.83 30.56l.697.726c1.156 1.203 3.024 1.346 4.17.32l2.078-1.86v27.23A3.023 3.023 0 0010.798 60h10.833a3.023 3.023 0 003.024-3.023V37.926h13.818v19.05c-.024 1.67 1.17 3.023 2.84 3.023h11.48a3.023 3.023 0 003.024-3.024V30.13s.574.502 1.281 1.124c.707.621 2.19.123 3.314-1.113l.788-.866z"
                  ></path>
                </svg>
              </span>
              <span className="inline-block mt-1">Location</span>
              <i className="arrow-down inline-block m-2"></i>

              <div className="location-drawer-wrapper absolute top-[100%] right-0 w-[360px]">
                <LocationDrawer />
              </div>
            </div>


            {/* Help Menu */}
            <div className="help-menu hidden mlg:flex justify-center items-center h-[100%] px-3">
              <Link to="/help" className="flex items-center justify-center text-center" state={{ lineHeight: "55px", boxSizing: "border-box" }}>

                <svg
                  className="pr-2 question"
                  style={{
                    display: "inline-block",
                    verticalAlign: "middle",
                  }}
                  width="20px"
                  height="16px"
                  fill="none"
                  viewBox="0 0 44 60"
                >
                  <path d="M26.63 44.999H15.378c-.5 0-.937.188-1.312.563-.376.375-.563.812-.563 1.312v11.251c0 .5.188.938.563 1.313.375.374.812.562 1.312.562h11.25c.5 0 .938-.188 1.312-.563.376-.374.564-.812.564-1.312v-11.25c0-.5-.188-.938-.564-1.313-.373-.376-.812-.563-1.312-.563zM41.396 11.32c-1.282-2.36-2.937-4.351-4.97-5.977-2.03-1.624-4.312-2.922-6.843-3.89C27.052.485 24.536 0 22.036 0c-9.5 0-16.75 4.156-21.75 12.47a1.85 1.85 0 00-.258 1.311c.078.469.32.86.727 1.172l7.687 5.86c.407.25.797.374 1.173.374.562 0 1.046-.25 1.453-.75 2.25-2.812 3.922-4.609 5.015-5.39 1.344-.907 3.031-1.36 5.063-1.36 1.906 0 3.585.5 5.039 1.5 1.453 1 2.179 2.156 2.179 3.469 0 1.468-.39 2.656-1.172 3.563-.78.905-2.094 1.78-3.938 2.624-2.437 1.094-4.672 2.79-6.702 5.086-2.032 2.297-3.047 4.743-3.047 7.336v2.11c0 .594.163 1.18.492 1.758.328.578.726.867 1.195.867h11.25c.5 0 .938-.242 1.313-.728.374-.483.562-.99.562-1.521 0-.781.43-1.83 1.289-3.14.86-1.313 1.93-2.33 3.212-3.049a95.29 95.29 0 002.858-1.664c.689-.421 1.587-1.102 2.697-2.039 1.109-.938 1.968-1.868 2.577-2.79.61-.92 1.157-2.116 1.641-3.586.485-1.468.727-3.047.727-4.734 0-2.594-.641-5.07-1.922-7.428z"></path>
                </svg>


                <span className="inline-block box-border">Help</span>
              </Link>
            </div>

            {/* Language Menuu */}
            <div className="lang-menu hidden mlg:flex justify-center items-center h-[100%] px-3">
              <button className="font-bold">EN</button>&nbsp;|&nbsp;<button className="font-bold">বাং</button>
            </div>

            {/* Sign In Menu */}
            <div onClick={signInBtnClickHandler} className="signin-btn hidden md:flex justify-center items-center px-[50px]">
              Sign In
            </div>

            {/* More Menu:Mobile, Dot Menu */}
            <div className="more-menu flex md:hidden justify-center items-center" onClick={moreBtnClickHandler}>
              <div className="dot-menu flex flex-col px-2 justify-between">
                <span className="dot-menu__dot h-[4px] w-[4px] inline-blockm mb-1">
                  &nbsp;
                </span>
                <span className="dot-menu__dot h-[4px] w-[4px]  inline-blockm mb-1">
                  &nbsp;
                </span>
                <span className="dot-menu__dot h-[4px] w-[4px]  inline-block">
                  &nbsp;
                </span>
              </div>
            </div>
          </div>
        </div>

        <div className="layer-2 h-[30px] w-[100%] block md:hidden">
          <div className="flex justify-between items-center h-[30px] px-2">
            <span className="flex items-center">
              <span className="inline-block px-2 mb-2">
                <svg
                  className="home"
                  style={{
                    display: "inline-block",
                    verticalAlign: "middle",
                  }}
                  width="20px"
                  height="17px"
                  fill="none"
                  viewBox="0 0 62 60"
                >
                  <path d="M61.2 29.274a2.955 2.955 0 00-.243-4.229L33.26.81c-1.257-1.1-3.272-1.077-4.503.052L.964 26.338A2.938 2.938 0 00.83 30.56l.697.726c1.156 1.203 3.024 1.346 4.17.32l2.078-1.86v27.23A3.023 3.023 0 0010.798 60h10.833a3.023 3.023 0 003.024-3.023V37.926h13.818v19.05c-.024 1.67 1.17 3.023 2.84 3.023h11.48a3.023 3.023 0 003.024-3.024V30.13s.574.502 1.281 1.124c.707.621 2.19.123 3.314-1.113l.788-.866z"></path>
                </svg>
              </span>
              <span className="inline-block pl-1 font-semibold text-white">Location</span>
            </span>
            <span className="px-2">
              <i className="arrow-right inline-block"></i>
            </span>
          </div>

          <div className="location-drawer-wrapper-mobile w-[100%]">
            <LocationDrawer />
          </div>
        </div>

      </div>

      <div className={"sign-in-modal-wrapper w-[100vw] h-[100vh] " + (ShowSignInModal ? "" : "hidden")}>
        <div className="inner-wrapper flex justify-center items-center">
          <SignInModal signInModalClose={signInModalClose} />
        </div>
      </div>

      <div className={"more-modal-wrapper w-[100vw] h-[100vh] " + (ShowMoreModal ? "" : "hidden")}>
        <div className="inner-wrapper flex justify-center items-center" onClick={moreModalClose}>
          <MoreModal />
        </div>
      </div>
    </React.Fragment>
  );
}

export default Header;
