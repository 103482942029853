import { useEffect } from "react";

const PrivacyPolicy = (props) => {
  useEffect(() => props.changeHeaderImage(props.headerImage));

  return (
    <div className="py-5 text-[#615e58]">
      <div>
        <br />
        When you use our Website, we collect and store your personal information
        which is provided by you from time to time. Our primary goal in doing so
        is to provide you a safe, efficient, smooth and customized experience.
        This allows us to provide services and features that most likely meet
        your needs, and to customize our website to make your experience safer
        and easier. More importantly, while doing so, we collect personal
        information from you that we consider necessary for achieving this
        purpose.&nbsp;
        <br />
        <br />
      </div>
      <div>
        <br />
        Below are some of the ways in which we collect and store your
        information:&nbsp;
        <br />
        <br />
      </div>
      <ul>
        <li>
          We receive and store any information you enter on our website or give
          us in any other way. We use the information that you provide for such
          purposes as responding to your requests, customizing future shopping
          for you, improving our stores, and communicating with you.
        </li>
        <li>
          We also store certain types of information whenever you interact with
          us. For example, like many websites, we use "cookies," and we obtain
          certain types of information when your web browser accesses
          Chaldal.com or advertisements and other content served by or on behalf
          of Chaldal.com on other websites.
        </li>
        <li>
          When signing up via Facebook, we collect your Name and Email (provided
          by Facebook) as part of your Chaldal account Information.
        </li>
        <li>
          To help us make e-mails more useful and interesting, we often receive
          a confirmation when you open e-mail from Chaldal if your computer
          supports such capabilities.
        </li>
      </ul>
      <div>
        <br />
        Changes To Your Information:
        <br />
        <br />
        The information you provide us isn’t set in stone. You may review,
        update, correct or delete the personal information in your profile at
        any time.&nbsp;
        <br />
        <br />
      </div>
      <ul>
        <li>
          If you would like us to remove your information from our records,
          please create a request at the{" "}
          <a href="https://chaldal.com/t/ContactUs">
            <strong>Contact Us</strong>
          </a>{" "}
          page.&nbsp;
        </li>
        <li>
          To Delete your Facebook login, visit the{" "}
          <a href="https://chaldal.com/t/ContactUs">
            <strong>Contact Us</strong>
          </a>{" "}
          page while logged in via Facebook. You should see a "Delete Facebook
          Login" option to create a request to remove Facebook login from your
          account.
        </li>
      </ul>
      <div>
        <br />
        Information about our customers is an important part of our business,
        and we are not in the business of selling it to others.&nbsp;
        <br />
        <br />
      </div>
      <div>
        We release account and other personal information when we believe
        release is appropriate to comply with the law; enforce or apply our
        Terms of Use and other agreements; or protect the rights, property, or
        safety of Chaldal.com, our users, or others. This includes exchanging
        information with other companies and organizations for fraud protection.
        <br />
        <br />
      </div>
    </div>
  );
};

export default PrivacyPolicy;
