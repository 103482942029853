import { useEffect } from "react";

const FAQ = (props) => {
  const preText = `
+------------+------------------+------------------+
|            | Orders below 400 | Orders above 400 |
+------------+------------------+------------------+
| Dhaka      | 29               | 19               |
+------------+------------------+------------------+
| Chattagram | 19               | 9                |
+------------+------------------+------------------+
| Jashore    | 19               | 1 (Tk 300)       |
+------------+------------------+------------------+
| Khulna     | 19               | 1 (Tk 300)       |
+------------+------------------+------------------+
| Rajshahi   | 19               | 1 (Tk 300)       |
+------------+------------------+------------------+
| Sylhet     | 19               | 9                |
+------------+------------------+------------------+
| Gazipur    | 9                | 9                |
+------------+------------------+------------------+
`;

  useEffect(() => props.changeHeaderImage(props.headerImage));

  return (
    <div className="py-5 text-[#615e58]">
      <div>
        <strong>
          <br />
          Q. How does the site work?
        </strong>
        &nbsp;
        <br />
        <br />
      </div>
      <div>
        <br />
        A. You can browse the site or use our search engine to find your desired
        products. You can then add them to your shopping bag and click on place
        order. You let us know your address, select a delivery time – and voila,
        you are done.&nbsp; A Chaldal representative will then deliver your
        order right to your home or office.&nbsp;
        <br />
        <br />
      </div>
      <div>
        <strong>
          <br />
          Q. How much do deliveries cost?
        </strong>
        &nbsp;
        <br />
        <br />
      </div>
      <div>
        <br />
      </div>
      <div>A. The delivery fee across the country is in the table below:</div>
      <div>
        <br />
      </div>
      <pre>{preText}</pre>
      <div>
        <br />
        <br />
        <br />
        <strong>Q. How can I contact you?</strong>&nbsp;
        <br />
        <br />
      </div>
      <div>
        <br />
        A. You can always call 16710 or mail us at support@chaldal.com.&nbsp;
        <br />
        <br />
      </div>
      <div>
        <br />
      </div>
      <div>
        <strong>
          <br />
          Q. What are your delivery hours?
        </strong>
        &nbsp;
        <br />
        <br />
      </div>
      <div>
        <br />
        A. We deliver from 7.30 am to 11 pm every day. You can choose from
        available slots to find something that is convenient to you.&nbsp;
        <br />
        <br />
      </div>
      <div>
        <strong>
          <br />
          Q. How do I know when my order is here?
        </strong>
        &nbsp;
        <br />
        <br />
      </div>
      <div>
        <br />
        A. A Chaldal representative will call you as soon as they are at your
        house to let you know about your delivery.&nbsp;
        <br />
        <br />
      </div>
      <div>
        <strong>
          <br />
          Q. How do I pay?
        </strong>
        &nbsp;
        <br />
        <br />
      </div>
      <div>
        <br />
        A. We accept cash on delivery and we also have Online Credit Card and
        Online bKash service. Don’t worry, our Chaldal representatives should
        always carry enough change.&nbsp;
        <br />
        <br />
      </div>
      <div>
        <strong>
          <br />
          Q. Do you serve my area?
        </strong>
        &nbsp;
        <br />
        <br />
      </div>
      <div>
        <br />
        A. We are currently serving five big cities of Bangladesh including
        Dhaka, Chattogram, Narayanganj, Jashore, and Khulna.
        <br />
        <br />
      </div>
      <div>
        <strong>
          <br />
          Q. I can’t find the product I am looking for. What do I do?
        </strong>
        &nbsp;
        <br />
        <br />
      </div>
      <div>
        <br />
        A. We are always open to new suggestions and will add an item to our
        inventory just for you. There is a "Product Request" feature that you
        can use to inform us of your requirement. You can also call 16710 or
        mail us at support@chaldal.com to add an item to our inventory.&nbsp;
        <br />
        <br />
      </div>
      <div>
        <strong>
          <br />
          Q. My order is wrong. What do I do?
        </strong>
        &nbsp;
        <br />
        <br />
      </div>
      <div>
        <br />
        A. Please Immediately call 16710 and let us know the problem.&nbsp;
        <br />
        <br />
      </div>
      <div>
        <strong>
          <br />
          Q. What if the item is out of stock?
        </strong>
        &nbsp;
        <br />
        <br />
      </div>
      <div>
        <br />
        A. We hold our own inventory in our warehouses, so we rarely run out of
        stock. However, we will try our best to source what you need. If we
        cannot find it, we will SMS/call you and let you know what substitutes
        are available.&nbsp;
        <br />
        <br />
      </div>
      <div>
        <strong>
          <br />
          Q. What happens during a hartal?
        </strong>
        &nbsp;
        <br />
        <br />
      </div>
      <div>
        <br />
        A. We work during hartals. That’s how dedicated we are.&nbsp;
        <br />
        <br />
      </div>
      <div>
        <strong>
          <br />
          Q. Why should we buy from you when I have a store nearby?
        </strong>
        &nbsp;
        <br />
        <br />
      </div>
      <div>
        <br />
        A. Well, that is up to you but you can also be a couch potato like our
        founders and have your items delivered to your house for free. Our
        prices are sometimes lower than that of major superstores in the city.
        We also carry a larger variety than the average corner store. So, there
        is really no reason to not buy from us.&nbsp;
        <br />
        <br />
      </div>
      <div>
        <strong>
          <br />
          Q. What about the prices?
        </strong>
        &nbsp;
        <br />
        <br />
      </div>
      <div>
        <br />
        A. Our prices are our own but we try our best to offer them to you at or
        below market prices. Our prices are the same as the local market and we
        are working hard to get them even lower! If you feel that any product is
        priced unfairly, please let us know.&nbsp;
        <br />
        <br />
      </div>
      <div>
        <strong>
          <br />
          Q. How do you deliver?
        </strong>
        &nbsp;
        <br />
        <br />
      </div>
      <div>
        <br />
        A. We use our own fleet to deliver. Our goal is to deliver the products
        to your place on time.&nbsp;
        <br />
        <br />
      </div>
      <div>
        <strong>
          <br />
          Q. What is your policy on refunds?
        </strong>
        &nbsp;
        <br />
        <br />
      </div>
      <div>
        <br />
        A. You may get a refund within seven (7) days on unopened or spoilt
        packaged products. For diapers and perishable products, such as milk,
        fruits, and fresh vegetables, we have a one (1) day return policy. We
        will not accept any diaper items as returns if more than 20% of the pack
        has been used. We reserve the right to refuse a refund. Please contact
        us at 16710 if you want to return an item.&nbsp;
        <br />
        <br />
      </div>
      <div>
        <strong>
          <br />
          Q. Can I get a refund via cash?
        </strong>
        &nbsp;
        <br />
        <br />
      </div>
      <div>
        <br />
        A. No, for any sort of issue, we will update the credit to your Chaldal
        account.&nbsp;
        <br />
        <br />
      </div>
      <div>
        <strong>
          <br />
          Q. What is your discounting policy?
        </strong>
        &nbsp;
        <br />
        <br />
      </div>
      <div>
        <br />
        A. We try to provide the best deals in Dhaka and many of our products
        are already discounted below the maximum retail price (MRP). We also
        offer discount codes under special circumstances, which are applied on
        the MRP. On any given product, we can only apply one type of discount.
        We always consider the best discount available to the customer. For
        example: If the MRP of a product is Tk. 100 and our list price is Tk. 92
        -- the product is already sold at an 8% discount. This means that if the
        user applies a discount code for a 5% discount, we will still consider
        the best discount available to the user and sell the product at Tk.
        92.&nbsp;
        <br />
        <br />
      </div>
      <div>
        <strong>
          <br />
          Q.&nbsp; Can I order over the phone?
        </strong>
        &nbsp;
        <br />
        <br />
      </div>
      <div>
        <br />
        A. Absolutely. Our hotline is 16710.&nbsp;
        <br />
        <br />
      </div>
      <div>
        <strong>
          <br />
          Q. What about quality?
        </strong>
        &nbsp;
        <br />
        <br />
      </div>
      <div>
        <br />
        A. We try our best to source the best quality items for you but if you
        are dissatisfied, you can always send them back to the delivery person.
        If you forget to do that, you can call us within 3 days and we will
        replace the item for free.&nbsp;
        <br />
        <br />
      </div>
      <div>
        <strong>
          <br />
          Q. How are you sourcing your products?
        </strong>
        &nbsp;
        <br />
        <br />
      </div>
      <div>
        <br />
        A. We have deals with whole-sellers, manufacturers, and importers. We
        only sell authentic products.&nbsp;
        <br />
        <br />
      </div>
      <div>
        <strong>
          <br />
          Q. Should I tip the delivery representative?
        </strong>
        &nbsp;
        <br />
        <br />
      </div>
      <div>
        <br />
        A. Tips are not required. But our delivery team members appreciate
        recognition for their hard work. Delivery representatives keep the
        entire tip amount.
        <br />
        <br />
      </div>
    </div>
  );
};

export default FAQ;
