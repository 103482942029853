const SelectArea = () => {
    return ( <div className="w-[500px] m-5">
        <div className="select-area border p-2 flex items-center">
            <button><svg class="search" version="1.1" x="0px" y="0px" viewBox="0 0 100 100"><path d="M44.5,78.5c-18.8,0-34-15.3-34-34s15.3-34,34-34s34,15.3,34,34S63.3,78.5,44.5,78.5z M44.5,18.1  C30,18.1,18.2,30,18.2,44.5S30,70.8,44.5,70.8S70.9,59,70.9,44.5S59,18.1,44.5,18.1z"></path><path d="M87.2,91c-1,0-2-0.4-2.7-1.1L63.1,68.5c-1.5-1.5-1.5-3.9,0-5.4s3.9-1.5,5.4,0l21.3,21.3  c1.5,1.5,1.5,3.9,0,5.4C89.2,90.6,88.2,91,87.2,91z"></path></svg></button>
            <input className="pl-3 border-none focus:ring-0" type="text" name="" id="" />
        </div>
        <ul className="border-l border-r border-b cursor-pointer">
            <li className="py-2 px-2 border-b">Rampura</li>
            <li className="py-2 px-2 border-b">Banasree</li>
            <li className="py-2 px-2 border-b">Moghbazaar</li>
            <li className="py-2 px-2 border-b">Banani</li>
            <li className="py-2 px-2">Gulshan</li>
        </ul>
        <div className="flex justify-center mt-5">
            <button className="uppercase  border border-[#ff686e] text-[#ff686e] hover:bg-[#ffe7e8] px-5 py-2 rounded">Back</button>
        </div>
    </div> );
}
 
export default SelectArea;