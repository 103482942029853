import axios from "axios";

const host = "http://192.168.0.113";
const port = "5678";

axios.defaults.withCredentials = true;

const ApiClient = axios.create({
    baseURL: host+ ":" + port+'/api/',
});

export default ApiClient;