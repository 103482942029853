import FAQImg from "assets/img/help/faq.png";
import OurStoryImg from "assets/img/help/story.png";
import TeamImg from "assets/img/help/team.png";
import CareerImg from "assets/img/help/career.png";
import PrivacyPolicyImg from "assets/img/help/privacy.png";
import TermsOfUseImg from "assets/img/help/terms.png";
import ContactUsImg from "assets/img/help/contact.png";

import FAQ from "pages/Content/FAQ";
import OurStory from "pages/Content/OurStory";
import Team from "pages/Content/Team";
import Career from "pages/Content/Career";
import ContactUs from "pages/Content/ContactUs";
import PrivacyPolicy from "pages/Content/PrivacyPolicy";
import TermsOfUse from "pages/Content/TermsOfUse";
import { Routes, Route, Link } from "react-router-dom";
import React from "react";

class Help extends React.Component {
  state = {
    pageImg: "",
  };

  changeHeaderImage = (Img) => {
    this.setState({ pageImg: Img });
  };

  // componentDidMount(){
  //   switch (window.location.pathname) {
  //     case "/help/faq":
  //       this.setState({ pageImg: FAQImg })
  //       break;

  //     case "/help/our-story":
  //       this.setState({ pageImg: OurStoryImg })
  //       break;

  //     case "/help/team":
  //       this.setState({pageImg:TeamImg})
  //       break;

  //     case "/help/career":
  //       this.setState({pageImg:CareerImg})
  //       break;
  //     case "/help/contact-us":
  //       this.setState({pageImg:ContactUsImg});
  //       break;

  //     case "/help/privacy-policy":
  //       this.setState({pageImg:PrivacyPolicyImg});
  //       break;

  //     case "/help/terms-of-use":
  //       this.setState({pageImg:TermsOfUseImg})
  //       break;
  //     default:
  //       this.setState({pageImg:FAQImg})
  //   }
  // }

  render() {
    return (
      <div className="px-5 py-2">
        <div
          className="bg-"
          style={{
            backgroundImage: "url(" + this.state.pageImg + ")",
            backgroundSize: "auto",
            backgroundRepeat: "no-repeat",
          }}
        >
          <div className="h-[300px] relative flex justify-center">
            <ul className="flex items-center text-black absolute bottom-[20px] z-10">
              <li className="mx-3 text-white">
                <Link to="faq">FAQ</Link>
              </li>
              <li className="mx-3 text-white">
                <Link to="our-story">Our Story</Link>
              </li>
              <li className="mx-3 text-white">
                <Link to="team">Team</Link>
              </li>
              <li className="mx-3 text-white">
                <Link to="career">Career</Link>
              </li>
              <li className="mx-3 text-white">
                <Link to="contact-us">Contact Us</Link>
              </li>
              <li className="mx-3 text-white">
                <Link to="privacy-policy">Privacy Policy</Link>
              </li>
              <li className="mx-3 text-white">
                <Link to="terms-of-use">Terms Of Use</Link>
              </li>
            </ul>
          </div>
          <Routes>
            <Route
              path="faq"
              element={
                <FAQ
                  headerImage={FAQImg}
                  changeHeaderImage={this.changeHeaderImage}
                />
              }
            ></Route>
            <Route
              path="terms-of-use"
              element={
                <TermsOfUse
                  headerImage={TermsOfUseImg}
                  changeHeaderImage={this.changeHeaderImage}
                />
              }
            ></Route>
            <Route
              path="privacy-policy"
              element={
                <PrivacyPolicy
                  headerImage={PrivacyPolicyImg}
                  changeHeaderImage={this.changeHeaderImage}
                />
              }
            ></Route>
            <Route
              path="our-story"
              element={
                <OurStory
                  headerImage={OurStoryImg}
                  changeHeaderImage={this.changeHeaderImage}
                />
              }
            ></Route>
            <Route
              path="team"
              element={
                <Team
                  headerImage={TeamImg}
                  changeHeaderImage={this.changeHeaderImage}
                />
              }
            ></Route>
            <Route
              path="our-story"
              element={
                <OurStory
                  headerImage={OurStoryImg}
                  changeHeaderImage={this.changeHeaderImage}
                />
              }
            ></Route>
            <Route
              path="contact-us"
              element={
                <ContactUs
                  headerImage={ContactUsImg}
                  changeHeaderImage={this.changeHeaderImage}
                />
              }
            ></Route>
            <Route
              path="career"
              element={
                <Career
                  headerImage={CareerImg}
                  changeHeaderImage={this.changeHeaderImage}
                />
              }
            ></Route>
            <Route
              path="*"
              element={
                <FAQ
                  headerImage={FAQImg}
                  changeHeaderImage={this.changeHeaderImage}
                />
              }
            ></Route>
          </Routes>
        </div>
      </div>
    );
  }
}

export default Help;
