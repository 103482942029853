const UserPassword = () => {
    return (<div>
        <div className="text-2xl text-[#ff9a1f] font-bold">Change your password</div>
        <form>
            <div>
                <label htmlFor="oldpassword">Old Password</label>
                <input type="text" name="oldpassword" id="" />
            </div>

            <div>
                <label htmlFor="newpassword">New Password</label>
                <input type="password" name="newpassword" id="" />
            </div>
            <div>
                <label htmlFor="confirmpassword">Re-type new Password</label>
                <input type="password" name="confirmpassord" id="" />
            </div>

            <div>
               <button className="bg-[#ff686e] cursor-pointer font-bold text-md p-2 rounded text-white hover:bg-[#ff1b25] active:relative active:top-[1px]" type="submit">
                   Change Password
               </button>
            </div>
        </form>
    </div>);
}

export default UserPassword;