export default {

    "app_name": "Baroari",

    //long-description
    "long_desc": "Baroari.com is an E-commerce Shop committed to bring you the best Traditional, Organic, Homemade, Assorted Regular Consumer Products at your doorstep." ,
    "short_desc": "",
    "support_email": "support@baroari.com",
    "hotline_number": "16000",
    "searchbar_placeholder": "পণ্য খুঁজুন (যেমন, ডিম, দুধ, আলু)",
}