import LeftSidebar from "components/LeftSidebar";
import Cart from "components/Cart/Cart";
import Mainbar from "./Mainbar";
import { useState } from "react";
import EventBus from "services/utils/EventBus";
import { useEffect } from "react";

import "components/styles/SiteBody.scss";

function SiteBody() {

  const [ShowSidebarOnDesktop, setShowSidebarOnDesktop] = useState(true);
  const [ShowSidebarOnMobile, setShowSidebarOnMobile] = useState(false);

  useEffect(() => {
    EventBus.on("hamburgClickedOnDesktop", () => {
      setShowSidebarOnDesktop(!ShowSidebarOnDesktop);
    })
  })

  useEffect(() => {
    EventBus.on("hamburgClickedOnMobile", () => {
      setShowSidebarOnMobile(!ShowSidebarOnMobile);
    })
  })

  return (
    <div className="SiteBody">
      <div className={"LeftSidebar-Wrapper " + (ShowSidebarOnDesktop ? " in-desktop-open " : "") + (ShowSidebarOnMobile ? " in-mobile-open " : "")}>
        <LeftSidebar />
      </div>
      <div className={"Mainbar-Wrapper " + (ShowSidebarOnDesktop ? " with-sidebar-desktop " : "") + (ShowSidebarOnMobile ? " with-sidebar-mobile " : "")}>
        <Mainbar />
      </div>
      <div className="Cart-Wrapper">
        <Cart />
      </div>
    </div>
  );
}

export default SiteBody;
