import "components/styles/LeftSidebar.scss";
import Categories from "components/Category/Categories";

function LeftSidebar() {
  return (
    <div>
      <div className="p-2 border-b">
        <div className="flex items-center py-3 px-3 ">
          <div>Offers</div>
        </div>
      </div>
      <div className="pl-1 py-3">
        <Categories />
      </div>
    </div>
  );
}

export default LeftSidebar;
