import React from "react";
import Header from "components/Header/Header";
import SiteBody from "components/SiteBody";
import BottomNav from "components/BottomNav/BottomNav";

import "./App.css";
import "./App.scss";
import "./_globals.scss";


function App() {
  return (
    <React.Fragment>
      <Header />
      <SiteBody />
      <BottomNav />
    </React.Fragment>
  );
}

export default App;
