import { useEffect } from "react";

const TermsOfUse = (props) => {
  useEffect(() => props.changeHeaderImage(props.headerImage));

  return (
    <div className="py-5 text-[#615e58]">
      <div>
        <strong>1.&nbsp; &nbsp; &nbsp; &nbsp; &nbsp;Terms of Use</strong>&nbsp;
      </div>
      <div>
        <br />
        1.1&nbsp; &nbsp; &nbsp; &nbsp;Welcome to Chaldal.com. Chaldal Limited (“
        <strong>Chaldal</strong>”) provides access to the Chaldal.com website or
        the mobile application/app or any other media{" "}
        <strong>("Website") </strong>to you subject to the terms and conditions
        (“<strong>Terms</strong>”) set out on this page. By using the Website,
        you, a registered or guest user in terms of the eligibility criteria set
        out herein (“User”) agree to be bound by the Terms. Please read them
        carefully as your continued usage of the Website, you signify your
        agreement to be bound by these Terms. If you do not want to be bound by
        the Terms, you must not subscribe to or use the Website or our
        services.&nbsp;
        <br />
        <br />
      </div>
      <div>
        1.2&nbsp; &nbsp; &nbsp; &nbsp;By impliedly or expressly accepting these
        Terms, you also accept and agree to be bound by Chaldal Policies
        (including but not limited to Privacy Policy available at{" "}
        <a href="https://chaldal.com/t/PrivacyInfo">
          <strong>https://chaldal.com/t/PrivacyInfo</strong>
        </a>
        ) as amended from time to time.&nbsp;
        <br />
        <br />
      </div>
      <div>
        1.3&nbsp; &nbsp; &nbsp; &nbsp;In these Terms, references to "you",
        "User" shall mean the end user/customer accessing the Website, its
        contents and using the Services offered through the Website. References
        to the “Website”, "Chaldal", “Chaldal.com”, "we", "us" and "our" shall
        mean the Website and/or Chaldal Limited.&nbsp;
        <br />
        <br />
      </div>
      <div>
        1.4&nbsp; &nbsp; &nbsp; &nbsp;The contents set out herein form an
        electronic record in terms of তথ্য ও যোগাযোগ প্রযুক্তি আইন, ২০০৬
        (Information &amp; Communication Technology Act, 2006) and rules there
        under as applicable and as amended from time to time. As such, this
        document does not require any physical or digital signatures and forms a
        valid and binding agreement between the Website and the User.&nbsp;
        <br />
        <br />
      </div>
      <div>
        1.5&nbsp; &nbsp; &nbsp; &nbsp;The Website is operated by Chaldal
        Limited., a company incorporated under the laws of Bangladesh having its
        registered office at 165 DIT Extension Road, Fakirapool, Dhaka,
        Bangladesh. All references to the Website in these Terms shall deem to
        refer to the aforesaid entity in inclusion of the online portal.&nbsp;
        <br />
        <br />
      </div>
      <div>
        1.6&nbsp; &nbsp; &nbsp; &nbsp;This Website may also contain links to
        other websites, which are not operated by Chaldal, and Chaldal has no
        control over the linked sites and accepts no responsibility for them or
        for any loss or damage that may arise from your use of them. Your use of
        the linked sites will be subject to the terms of use and service
        contained within each such site.&nbsp;
        <br />
        <br />
      </div>
      <div>
        1.7&nbsp; &nbsp; &nbsp; &nbsp;We reserve the right to change these Terms
        at any time. Such changes will be effective when posted on the Website.
        By continuing to use the Website after we post any such changes, you
        accept the Terms as modified.&nbsp;
        <br />
        <br />
      </div>
      <div>
        1.8&nbsp; &nbsp; &nbsp; &nbsp;These Terms will continue to apply until
        terminated by either You or Chaldal in accordance with the terms set out
        below:&nbsp;
      </div>
      <div>
        <br />
        1.9&nbsp; &nbsp; &nbsp; &nbsp;The agreement with Chaldal can be
        terminated by (i) not accessing the Website; or (ii) closing Your
        Account, if such option has been made available to You.&nbsp;
      </div>
      <div>
        <br />
        1.10&nbsp; &nbsp; Notwithstanding the foregoing, these provisions set
        out in these Terms which by their very nature survive are meant to
        survive termination, shall survive the termination / expiry of this
        agreement.&nbsp;
      </div>
      <div>
        <br />
      </div>
      <div>
        <strong>
          <br />
          2.&nbsp; &nbsp; &nbsp; &nbsp; Eligibility
        </strong>
        &nbsp;
      </div>
      <div>
        <br />
        2.1&nbsp; &nbsp; &nbsp; &nbsp;Use of the Website is available only to
        such persons who can legally contract under the Contract Act,
        1872.&nbsp;
        <br />
        <br />
      </div>
      <div>
        2.2&nbsp; &nbsp; &nbsp; &nbsp;If you are a minor i.e. under the age of
        18 years, you shall not register as a user of Chaldal.com and shall not
        transact on or use the Website. As a minor if you wish to use or
        transact on website, such use or transaction may be made by your legal
        guardian or parents on the Website. Chaldal reserves the right to
        terminate your membership and / or refuse to provide you with access to
        the website if it is brought to Chaldal’s notice or if it is discovered
        that you are under the age of 18 years.&nbsp;
      </div>
      <div>
        <br />
        2.3&nbsp; &nbsp; &nbsp; &nbsp;By accepting the Terms or using or
        transacting on the Website, the User irrevocably declares and undertakes
        that he/she is of legal age i.e. 18 years or older and capable of
        entering into a binding contract and such usage shall be deemed to form
        a contract between the Website and such User to the extent permissible
        under applicable laws.&nbsp;
        <br />
        <br />
      </div>
      <div>
        2.4&nbsp; &nbsp; &nbsp; The usage of the Services offered through the
        Website is only available if the address of the User to which the
        product(s) is/are to be delivered is within Dhaka City, Bangladesh;
        Chaldal is serving with 1 Hour Delivery Service, to Uttara, Tongi,
        Mirpur, Cantonment, Banani, Banani DOHS, Gulshan, Baridhara, Baridhara
        DOHS, Badda, Mohakhali, Mohakhali DOHS, Khilgaon, Paltan, Motijheel,
        Banasree, Moghbazar, Malibag, Razarbag, Kallyanpur, Shyamoli, Paikpara,
        Pallabi and Mohammedpur. For the rest of the Dhaka city Chaldal is
        currently delivering within 4-7 hours timeframe.&nbsp; Subject to the
        above, if the User specifies a convenient time and Chaldal will send the
        products during that time.&nbsp;
        <br />
        <br />
      </div>
      <div>
        <strong>
          <br />
          3.&nbsp; &nbsp; &nbsp; &nbsp; Communication
        </strong>
        &nbsp;
        <br />
        <br />
      </div>
      <div>
        3.1&nbsp; &nbsp; &nbsp; &nbsp;When you use Chaldal.com, or contact us
        via phone or email, you consent to receive communication from us. You
        authorize us to communicate with you by e-mail, SMS, phone call or by
        posting notices on the website or by any other mode of communication.
        For contractual purposes, you consent to receive communications
        (including transactional, promotional and/or commercial messages), from
        us with respect to your use of the website and/or your order placed on
        the Website.&nbsp; Additionally any disclosures posted on the site, or
        sent to you by email fulfill the legal obligation of communication made
        in writing.&nbsp;
        <br />
        <br />
      </div>
      <div>
        <strong>
          <br />
          4.&nbsp; &nbsp; &nbsp; &nbsp; Your Account and Responsibilities
        </strong>
        &nbsp;
        <br />
        <br />
      </div>
      <div>
        <br />
        4.1&nbsp; &nbsp; &nbsp; &nbsp;Any person may access the Website either
        by registering to the Website or using the Website as a guest. However,
        a guest user may not have access to all sections of the Website
        including certain benefits/promotional offers, which shall be reserved
        only for the purpose of registered Users, and which may change from time
        to time at the sole discretion of the Website.&nbsp;
        <br />
        <br />
      </div>
      <div>
        4.2&nbsp; &nbsp; &nbsp; &nbsp;If you wish to register your account with
        the Website, you shall be required to create an account by registering
        with a valid Bangladeshi mobile number and/or with your Facebook account
        or your email address or by filling in the details prescribed in the
        Website registration form. You will then receive a password or one-time
        PIN with which you can login to the website to place orders.&nbsp;
        <br />
        <br />
      </div>
      <div>
        4.3&nbsp; &nbsp; &nbsp; &nbsp;If you use the website, you will be
        responsible for maintaining the confidentiality of your username and
        password and you will be responsible for all activities that occur under
        your username and you will be under the obligation to restrict access to
        your computer or mobile phone to prevent unauthorised access to your
        account. You should inform us immediately if you have any reason to
        believe that your password has become known to anyone else, or if the
        password is being, or is likely to be, used in an unauthorised manner.
        You agree that if you provide any information that is untrue,
        inaccurate, not current or incomplete or we have reasonable grounds to
        suspect that such information is untrue, inaccurate, not current or
        incomplete, or not in accordance with this Terms of Use, we have the
        right to suspend or terminate your membership on the website.&nbsp;
        <br />
        <br />
      </div>
      <div>
        4.4&nbsp; &nbsp; &nbsp; &nbsp;The Website may be inaccessible for such
        purposes as it may, at its sole discretions deem necessary, including
        but not limited to regular maintenance. However, under no circumstances
        will Chaldal be held liable for any losses or claims arising out of such
        inaccessibility to the Users and the Users expressly waive any claims
        against Chaldal in this regard&nbsp;
        <br />
        <br />
        4.5&nbsp; &nbsp; &nbsp; &nbsp;Chaldal as a platform donot support or
        encourage multiple accounts owned by a single user even with different
        mobile numbers &amp; reserves the right to restrict , deactivate or
        disable any account that might fall under any sort of abuse to the
        service.
        <br />
        <br />
      </div>
      <div>
        <strong>
          <br />
          5.&nbsp; &nbsp; &nbsp; &nbsp; Charges
        </strong>
        &nbsp;
        <br />
        <br />
      </div>
      <div>
        5.1&nbsp; &nbsp; &nbsp; &nbsp;Membership on the website is free for
        users. Chaldal does not charge any fee for browsing and buying on the
        website. Chaldal reserves the right to change its fee policy from time
        to time. In particular, Chaldal may at its sole discretion introduce new
        services and modify some or all of the existing services offered on the
        website. In such an event, Chaldal reserves the right to introduce fees
        for the new services offered or amend/introduce fees for existing
        services, as the case may be. Changes to the fee policy will be posted
        on the website and such changes will automatically become effective
        immediately after they are posted on the website.&nbsp;
        <br />
        <br />
      </div>
      <div>
        <strong>
          <br />
          6.&nbsp; &nbsp; &nbsp; &nbsp; Copyright
        </strong>
        &nbsp;
        <br />
        <br />
      </div>
      <div>
        6.1&nbsp; &nbsp; &nbsp; &nbsp;The material (including the content, and
        any other content, software or services) contained on Chaldal.com are
        the property of Chaldal Limited, its subsidiaries, affiliates and/or
        third party licensors. No material on this site may be copied,
        reproduced, republished, installed, posted, transmitted, stored or
        distributed without written permission from Chaldal Limited.&nbsp;
        <br />
        <br />
      </div>
      <div>
        6.2&nbsp; &nbsp; &nbsp; &nbsp;You may not use any “deep-link”,
        “page-scrape”, “robot”, “spider” or other automatic device, program,
        algorithm or methodology, or any similar or equivalent manual process,
        to access, acquire, copy or monitor any portion of the website or any
        content, or in any way reproduce or circumvent the navigational
        structure or presentation of the website or any content, to obtain or
        attempt to obtain any materials, documents or information through any
        means not purposely made available through the website. We reserve our
        right to bar any such activity.&nbsp;
        <br />
        <br />
      </div>
      <div>
        <strong>
          <br />
          7.&nbsp; &nbsp; &nbsp; &nbsp; Cookies
        </strong>
        &nbsp;
        <br />
        <br />
      </div>
      <div>
        7.1&nbsp; &nbsp; &nbsp; &nbsp;This site uses cookies, which means that
        you must have cookies enabled on your computer in order for all
        functionality on this site to work properly. A cookie is a small data
        file that is written to your hard drive when you visit certain Web
        sites. Cookie files contain certain information, such as a random number
        user ID that the site assigns to a visitor to track the pages visited. A
        cookie cannot read data off your hard disk or read cookie files created
        by other sites. Cookies, by themselves, cannot be used to find out the
        identity of any user.&nbsp;
        <br />
        <br />
        <br />
      </div>
      <div>
        <strong>8.&nbsp; &nbsp; &nbsp; &nbsp; Promotional Activity</strong>
        &nbsp;
        <br />
        <br />
      </div>
      <div>
        8.1&nbsp; &nbsp; &nbsp; To promote its services Chaldal uses various
        advertisement and commercials which are truthful and are not deceptive
        or unfair to the best of our knowledge and belief. Every user is under
        the obligation to go through the relevant information contained in the
        Website before using the service and it will be assumed that each user
        is aware of every information provided in the Website. Images of
        products in the Website are for and by reference only and actual product
        may vary from the corresponding image exhibited. The Website disclaims
        any liabilities arising out of any discrepancies to this end to the
        fullest extent permitted by law.&nbsp;
        <br />
        <br />
        8.2&nbsp; &nbsp; &nbsp;Any short term or long term promotional acitivity
        or offer(s) are by default valid for a single order within the offered
        time frame under any specific account on chaldal.com. Any repetitive or
        suspicious or abusive practice during any offer or promotion might
        result as cancelled order &amp; chaldal reserves the right to do so to
        protect the rigths &amp; beniits of other users. Account that tends to
        abuse any offer by any means on the platform can also get termniated to
        maintain hygiene factor on the platform. The automated abuse detecction
        technology that chaldal has can be also enforced at anytime without any
        sort of prior notice.
        <br />
        <br />
        8.3&nbsp; &nbsp; Discount code(s) offered to any/all segment of&nbsp;
        customer(s) through different marketing channels might get disabled or
        extended by the authority at anytime without any prior notice. The
        modality can also be&nbsp; changed to accomodate with price changes or
        fluctuation &amp; intense of uses of the same by the user base to
        protect any sort of abuse or suspective uses.
        <br />
        <br />
      </div>
      <div>
        <strong>
          <br />
          9.&nbsp; &nbsp; &nbsp; &nbsp; The Contract
        </strong>
        &nbsp;
        <br />
        <br />
      </div>
      <div>
        <br />
        9.1&nbsp; &nbsp; &nbsp; Your order is an offer to us to buy the
        product(s) in your order. When you place an order to purchase a product
        from us, you will receive an e-mail and/or SMS to your mobile phone
        number confirming receipt of your order and/or containing the details of
        your order (the "Order Confirmation E-mail and/or SMS"). The Order
        Confirmation E-mail and/or SMS is acknowledgement that we have received
        your order, but does not confirm acceptance of your offer to buy the
        product(s) ordered; that when we send the Order Confirmation E-mail
        and/or SMS a contract called an “agreement to sell” is concluded in
        accordance with Section 4(3) of the Sale of Goods Act, 1930 i.e. the
        transfer of the property in the goods is to take place at a future time
        when the product(s) is/are delivered to your designated address. We only
        accept your offer, and the above “agreement to sell” becomes a contract
        of sale for product(s) ordered by you in accordance with Section 4(4) of
        the Sale of Goods Act, 1930, when the product(s) is/are delivered to
        your designated address and at that time the property in the goods is
        transferred from us to you.&nbsp;
        <br />
        <br />
      </div>
      <div>
        9.2 &nbsp; You can cancel your order for a product at no cost any time
        before the goods are delivered to you.&nbsp;
      </div>
      <div>
        <br />
        9.3 &nbsp; Please note that we sell products only in quantities which
        correspond to the typical needs of an average household. This applies
        both to the number of products ordered within a single order and the
        placing of several orders for the same product where the individual
        orders comprise a quantity typical for a normal household.&nbsp;
      </div>
      <div>
        <br />
      </div>
      <div>
        <strong>
          <br />
          10.&nbsp; &nbsp; &nbsp; &nbsp;Product Descriptions
        </strong>
        &nbsp;
        <br />
        <br />
      </div>
      <div>
        10.1&nbsp; &nbsp; &nbsp; Chaldal attempts to be as accurate as possible.
        Chaldal does not manufacture or produce any product by themselves.
        Therefore, Chaldal does not warrant that product descriptions or other
        content of any Chaldal product is accurate, complete, reliable, current,
        or error-free. If a product offered by Chaldal itself is not as
        described, your sole remedy is to return it in unused condition.&nbsp;
      </div>
      <div>&nbsp;</div>
      <div>
        <strong>
          <br />
          11.&nbsp; &nbsp; &nbsp; &nbsp;Pricing
        </strong>
        &nbsp;
        <br />
        <br />
      </div>
      <div>
        11.1&nbsp; &nbsp; &nbsp; Except where noted otherwise, the list price or
        suggested price displayed for products on Chaldal represents the full
        retail price listed on the product itself, suggested by the manufacturer
        or supplier, or estimated in accordance with standard industry practice;
        or the estimated retail value for a comparably featured item offered
        elsewhere. The list price or suggested price is a comparative price
        estimate and may or may not represent the prevailing price in every area
        on any particular day.&nbsp;
      </div>
      <div>
        <br />
        11.2&nbsp; &nbsp; &nbsp; Despite our best efforts, a small number of the
        items in our catalog may be mispriced. If the MRP of an item sold by
        Chaldal is higher than our stated price, we will, at our discretion,
        either contact you for instructions before shipping or cancel your order
        and notify you of such cancellation. And if the stated price on the
        product is lower than Chaldal, we will, either refund the amount or
        replace the product according to your preference, when
        acknowledged.&nbsp;
        <br />
        <br />
      </div>
      <div>
        <strong>
          <br />
          12.&nbsp; &nbsp; &nbsp; &nbsp;Return Policy
        </strong>
        &nbsp;
        <br />
        <br />
      </div>
      <div>
        12.1&nbsp; &nbsp; &nbsp;A user may return any product during the time of
        delivery, or within 7 days if:&nbsp;
        <br />
        <br />
      </div>
      <div>
        a)&nbsp; &nbsp; &nbsp; &nbsp; Product does not meet user’s
        expectation.&nbsp;
        <br />
        <br />
      </div>
      <div>
        <br />
        b)&nbsp; &nbsp; &nbsp; &nbsp; Found damaged during delivery&nbsp;
        <br />
        <br />
      </div>
      <div>
        <br />
        c)&nbsp; &nbsp; &nbsp; &nbsp; Have doubt about the product quality and
        quantity&nbsp;
        <br />
        <br />
      </div>
      <div>
        <br />
        d)&nbsp; &nbsp; &nbsp; &nbsp; Received in an unhealthy/ unexpected
        condition&nbsp;
        <br />
        <br />
      </div>
      <div>
        <br />
        e)&nbsp; &nbsp; &nbsp; &nbsp; Is not satisfied with the packaging&nbsp;
        <br />
        <br />
      </div>
      <div>
        <br />
        f)&nbsp; &nbsp; &nbsp; &nbsp; &nbsp;Finds product unusable&nbsp;
        <br />
        <br />
        <br />
        &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;We are continuously monitoring
        accounts of customers with excessive requests of returns and refunds. We
        take necessary steps to prevent this.&nbsp;
        <br />
        <br />
      </div>
      <div>
        <br />
        12.2&nbsp; &nbsp; &nbsp;A user may return any unopened or defective up
        to 20% and less, item within 7 days of receiving the item. But following
        products may not be eligible for return or replacement:&nbsp;
        <br />
        <br />
      </div>
      <div>
        a)&nbsp; &nbsp; &nbsp; &nbsp; Damages due to misuse of product&nbsp;
        <br />
        <br />
      </div>
      <div>
        <br />
        b)&nbsp; &nbsp; &nbsp; &nbsp; Incidental damage due to malfunctioning of
        product&nbsp;
        <br />
        <br />
      </div>
      <div>
        <br />
        c)&nbsp; &nbsp; &nbsp; &nbsp; Any consumable item which has been
        used/installed&nbsp;
        <br />
        <br />
      </div>
      <div>
        <br />
        d)&nbsp; &nbsp; &nbsp; &nbsp; Products with tampered or missing
        serial/UPC numbers&nbsp;
        <br />
        <br />
      </div>
      <div>
        <br />
        e)&nbsp; &nbsp; &nbsp; &nbsp; Any damage/defect which are not covered
        under the manufacturer's warranty&nbsp;
        <br />
        <br />
      </div>
      <div>
        <br />
        f)&nbsp; &nbsp; &nbsp; &nbsp; &nbsp;Any product that is returned without
        all original packaging and accessories, including the box,
        manufacturer's packaging if any, and all other items originally included
        with the product/s delivered.&nbsp;
        <br />
        <br />
      </div>
      <div>&nbsp;</div>
      <div>
        <br />
      </div>
      <div>
        <strong>13.&nbsp; &nbsp; &nbsp; &nbsp;Refund Policy</strong>
        <br />
        <br />
        13.1&nbsp; &nbsp; &nbsp;Chaldal tries its best to serve the users. But
        if under any circumstances, we fail to fulfill our commitment or to
        provide the service, we will notify you within 24 hours via phone/ text/
        email. If the service, that Chaldal fails to complete, requires any
        refund, it be done maximum within 7 Days after our acknowledgement.
        <br />
        <br />
        13.2&nbsp; &nbsp; &nbsp;Refund requests will be processed under
        mentioned situation:
        <br />
        <br />- Unable to serve with any product.
        <br />
        <br />- Customer returns any product from a paid order.
        <br />
        <br />- Customer cancels a paid order before it has been dispatched.
        <br />
        <br />
        For all the above three scenarios, the refund amount will be sent to
        Chaldal Balance of the customer. And the balance can only be used to
        purchase at Chaldal. Upon customer's request, Chaldal will transfer the
        refund amount to the user's original payment source within 7 days.
        Refund is only allowed for customers who have paid via bKash or card or
        other electronic method. For the orders that are paid via Cash, refund
        is only to be given through Chaldal Credits.&nbsp;
      </div>
      <div>
        <strong>
          <br />
        </strong>
        &nbsp;
      </div>
      <div>
        <strong>
          <br />
          14.&nbsp; &nbsp; &nbsp; &nbsp;Supply Policy:
        </strong>
        &nbsp;
        <br />
        <br />
      </div>
      <div>
        <br />
        14.1&nbsp; &nbsp; &nbsp;Chaldal, itself does not produce or manufacture
        any product, therefore Chaldal tries to control the quality, but do not
        give quality assurance for any. We believe the manufacturer/ vendor/
        supplier are fully capable of assuring the quality of every
        product.&nbsp;
        <br />
        <br />
      </div>
      <div>
        14.2&nbsp; &nbsp; &nbsp; If any user, at the time of receiving the order
        delivery, finds any product injurious to health/ life/ security, we will
        take full liability and will accept return of that product. If the user
        faces any threat after 12 hours of the provided delivery, we will
        consider it as a lacking within the quality assurance from the
        manufacturers or suppliers.&nbsp;
        <br />
        <br />
      </div>
      <div>
        14.3&nbsp; &nbsp; &nbsp; All products displayed on the website are
        subject to change and are subject to availability.&nbsp;
        <br />
        <br />
      </div>
      <div>&nbsp;</div>
      <div>
        <strong>
          <br />
          15.&nbsp; &nbsp; &nbsp; &nbsp;Modifications
        </strong>
        &nbsp;
        <br />
        <br />
      </div>
      <div>
        <br />
        15.1&nbsp; &nbsp; &nbsp; Chaldal reserves the right to modify these
        Terms and Conditions. Such modifications shall be effective immediately
        upon posting on Chaldal.com. You are responsible for the reviewing of
        such modifications. Your continued access or use of Chaldal.com shall be
        deemed your acceptance of the modified terms and conditions.&nbsp;
        <br />
        <br />
      </div>
      <div>
        <br />
      </div>
      <div>
        <strong>16.&nbsp; &nbsp; &nbsp; &nbsp;Chaldal Software Terms</strong>
        &nbsp;
        <br />
        <br />
      </div>
      <div>
        <br />
        16.1&nbsp; &nbsp; &nbsp; Use of the Chaldal Software/Application/App :
        You may use Chaldal Software/ Application/ App solely for purposes of
        enabling you to use and enjoy the services as provided by Chaldal, and
        as permitted by these Terms. You may not incorporate any portion of the
        Chaldal Software / Application/ App into your own programs or compile
        any portion of it in combination with your own programs, transfer it for
        use with another service, or sell, rent, lease, lend, loan, distribute
        or sub-license the Chaldal Software / Application/ App or otherwise
        assign any rights to the Chaldal Software / Application/ App in whole or
        in part. You may not use the Chaldal Software / Application/ App for any
        illegal purpose. We may cease providing any Chaldal Software /
        Application/ App and we may terminate your right to use any Chaldal
        Software / Application/ App at any time. Your rights to use the Chaldal
        Software / Application/ App will automatically terminate without notice
        from us if you fail to comply with any of these Terms. All software used
        in any Chaldal Service is the property of Chaldal and/or its affiliates
        or its software suppliers and protected by laws of Bangladesh including
        but not limited to any other applicable copyright laws.&nbsp;
        <br />
        <br />
      </div>
      <div>
        16.2&nbsp; &nbsp; &nbsp; Use of Third Party Services: When you use the
        Chaldal Software / Application/ App, you may also be using the services
        of one or more third parties, such as a wireless carrier or a mobile
        platform provider. Your use of these third party services may be subject
        to the separate policies, terms of use, and fees of those third
        parties.&nbsp;
        <br />
        <br />
      </div>
      <div>
        16.3&nbsp; &nbsp; &nbsp; No Reverse Engineering: You may not, and you
        will not encourage, assist or authorize any other person to copy,
        modify, reverse engineer, decompile or disassemble, or otherwise tamper
        with, the Chaldal Software / Application/ App, whether in whole or in
        part, or create any derivative works from or of the Chaldal Software /
        Application/ App.&nbsp;
        <br />
        <br />
      </div>
      <div>
        16.4&nbsp; &nbsp; &nbsp; Updates: In order to keep the Chaldal Software
        / Application/ App up-to-date, we may offer automatic or manual updates
        at any time and without notice to you.&nbsp;
        <br />
        <br />
      </div>
      <div>&nbsp;</div>
      <div>
        <br />
        &nbsp;
      </div>
    </div>
  );
};

export default TermsOfUse;
